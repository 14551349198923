import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {AdvertisedEvent} from "../domain/event.model";
import {EventOrt} from "../domain/eventOrt.model";
import {Catalogue} from "../domain/catalogue.model";

@Injectable({providedIn: 'root'})
export class EventService {

  constructor(private httpClient: HttpClient) {
  }

  getEvents(): Observable<AdvertisedEvent[]> {
    return this.httpClient.get<AdvertisedEvent[]>(`api/events`)
  }

  getEventById(eventId: string): Observable<AdvertisedEvent>  {
    return this.httpClient.get<AdvertisedEvent>(`api/events/${eventId}`);
  }

  getEventOrts(): Observable<EventOrt[]> {
    return this.httpClient.get<EventOrt[]>(`api/event-orts`)
  }

  createEvent(event: AdvertisedEvent): Observable<AdvertisedEvent> {
    return this.httpClient.post<AdvertisedEvent>(`api/events`, event);
  }

  updateEvent(event: AdvertisedEvent): Observable<AdvertisedEvent> {
    return this.httpClient.put<AdvertisedEvent>(`api/events`, event);
  }

  deleteEvent(key: number): Observable<any> {
    return this.httpClient.delete<any>(`api/events/${key}`);
  }

  uploadImage(adEvent: AdvertisedEvent, file: File): Observable<any> {
    const myFormData: FormData = new FormData();
    myFormData.append('file', file, file.name);
    return this.httpClient.post(`api/advertisingMediums/${adEvent.id}/images/upload-image`, myFormData, {reportProgress: true});
  }

  getImage(adEvent: AdvertisedEvent): Observable<any> {
    return this.httpClient.get<any>(`api/advertisingMediums/${adEvent.id}/images/get-image`);
  }
}
