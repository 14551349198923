import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Utf8DecoderService {
  /**
   * Decodiert einen UTF-8-kodierten String, falls es sich um einen String handelt.
   * Nicht-String-Werte werden unverändert zurückgegeben.
   *
   * @param value - Der Wert, der decodiert werden soll.
   * @returns Der decodierte String oder der Originalwert.
   */
  decodeString<T>(value: T): T {
    if (typeof value === 'string') {
      try {
        return decodeURIComponent(escape(value)) as T;
      }
      catch {
        return value;
      }
    }
  return value;
  }
}
