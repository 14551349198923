import {Injectable, WritableSignal} from '@angular/core';
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

@Injectable({providedIn: 'root'})
export class AdvertisementEditUtilityService {

  constructor() {}

  handleImageSelection(event: Event, selectedImage: WritableSignal<File | null>, imagePreview: WritableSignal<string | ArrayBuffer | null>): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      selectedImage.set(file);

      const reader = new FileReader();
      reader.onload = () => {
        imagePreview.set(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }

  parseDateToStruct(date: Date): NgbDateStruct {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  convertToDate(dateStruct: NgbDateStruct): Date {
    return new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day);
  }

  private adjustToLocalDate(dateStruct: NgbDateStruct): string {
    const date = new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day);
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return localDate.toISOString();
  }

  prepareSubmission<T extends { start: Date; end: Date; _class: string; previousAdvertisingMedium?: { _class: string } }>(
    entity: T,
    startDateStruct: NgbDateStruct,
    endDateStruct: NgbDateStruct,
    entityClass: string
  ): void {
    entity.start = this.adjustToLocalDate(startDateStruct) as unknown as Date;
    entity.end = this.adjustToLocalDate(endDateStruct) as unknown as Date;
    entity._class = entityClass;

    if (entity.previousAdvertisingMedium) {
      entity.previousAdvertisingMedium._class = entityClass;
    }
  }
}
