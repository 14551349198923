@if (newsLetter) {
  @if (userRoles().includes('ADMIN')) {
    <div class="d-flex align-items-center justify-content-start mb-4">
      <h2 class="me-4">Bearbeiten</h2>
      <button class="btn btn-outline-primary" (click)="toggleReadonly()">
        {{ readonly() ? 'Aktivieren' : 'Deaktivieren' }}
      </button>
    </div>
  }
  <form #catalogueForm="ngForm" (ngSubmit)="onSubmit(catalogueForm)">
    <div class="mb-3">
      <h3>Beschreibung</h3>
      <div class="row">
        <div class="col-md-4 col-lg-4">
          <label for="name" class="form-label">WTR<span class="text-danger">*</span></label>
          <input
            type="text"
            id="name"
            class="form-control"
            name="name"
            [(ngModel)]="newsLetter.name"
            #name="ngModel"
            required
            [disabled]="readonly()"
          />
          @if (name.invalid && name.touched) {
            <div class="text-danger">
              Der Name ist erforderlich.
            </div>
          }
        </div>
        <div class="col-md-4">
          <label for="description" class="form-label">Beschreibung<span class="text-danger">*</span></label>
          <input
            type="text"
            id="description"
            class="form-control"
            name="description"
            [(ngModel)]="newsLetter.description"
            #description="ngModel"
            required
            [disabled]="readonly()"
          >
          @if(description.invalid && description.touched){
            <div class="text-danger">
              Die Beschreibung ist erforderlich.
            </div>
          }
        </div>
        <div class="col-md-4">
          <label for="status" class="form-label">Status<span class="text-danger">*</span></label>
          <select
            id="status"
            class="form-select"
            name="status"
            [(ngModel)]="newsLetter.status"
            #status="ngModel"
            required
            [disabled]="readonly()"
          >
            @if (statusLookup.length > 0) {
              @for ( option of statusLookup ; track $index) {
                <option [value]="option">{{ option }}</option>
              }
            }
          </select>
          @if(status.invalid && status.touched){
            <div class="text-danger">
              Der Status ist erforderlich.
            </div>
          }
        </div>
      </div>
    </div>

    <div class="mb-3">
      <h3>Zeitraum</h3>
      <div class="row row-cols-sm-auto">
        <div class="col-md-6">
          <label for="start" class="form-label">Start<span class="text-danger">*</span></label>
          <div class="input-group">
            <input
              id="start"
              class="form-control"
              name="start"
              ngbDatepicker
              #dpStart="ngbDatepicker"
              [(ngModel)]="startDateStruct"
              (dateSelect)="onDateSelect($event, 'start')"
              required
              #startModel="ngModel"
              [disabled]="readonly()"
            />
            <button class="btn btn-outline-secondary bi bi-calendar3" [disabled]="readonly()" (click)="dpStart.toggle()"  type="button"></button>
          </div>
          @if (startModel.invalid && startModel.touched) {
            <div class="text-danger">
              Das Startdatum ist erforderlich.
            </div>
          }
        </div>
        <div class="col-md-6">
          <label for="end" class="form-label">Ende<span class="text-danger">*</span></label>
          <div class="input-group">
            <input
              id="end"
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="end"
              ngbDatepicker
              #dpEnd="ngbDatepicker"
              [(ngModel)]="endDateStruct"
              (dateSelect)="onDateSelect($event, 'end')"
              required
              #endModel="ngModel"
              [disabled]="readonly()"
            />
            <button class="btn btn-outline-secondary bi bi-calendar3"  [disabled]="readonly()" (click)="dpEnd.toggle()"  type="button"></button>
          </div>
          @if (endModel.invalid && endModel.touched) {
            <div class="text-danger">
              Das Enddatum ist erforderlich.
            </div>
          }
        </div>
      </div>
    </div>

    <div class="mb-3">
      <h3>Kategorisierung</h3>
      <div class="row">
        <div class="col-md-4">
          <label for="previousAdvertisingMedium" class="form-label">Vorjahresaktion</label>
          <select
            id="previousAdvertisingMedium"
            class="form-select"
            name="previousAdvertisingMedium"
            [(ngModel)]="newsLetter.previousAdvertisingMedium"
            #previousAdvertisingMedium="ngModel"
            [disabled]="readonly()"
          >
            <option [ngValue]="null" disabled selected>Bitte auswählen</option>
            @if (previousAdvMLookup.length > 0) {
              @for ( previousAdvMLookup of previousAdvMLookup ; track previousAdvMLookup.id) {
                <option [ngValue]="previousAdvMLookup">
                  {{ previousAdvMLookup?.name }}
                </option>
              }
            }
          </select>
        </div>
        <div class="col-md-4">
          <label for="campaign" class="form-label">Kampagne</label>
          <select
            id="campaign"
            class="form-select"
            name="campaign"
            [(ngModel)]="newsLetter.campaign"
            #campaign="ngModel"
            [disabled]="readonly()"
          >
            <option [ngValue]="null" disabled selected>Bitte auswählen</option>
            @if(campaignLookup.length > 0) {
              @for (campaign of campaignLookup; track campaign.id) {
                <option [ngValue]="campaign">
                  {{ campaign?.name }}
                </option>
              }
            }
          </select>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <h3>Werbebudgetierung</h3>
      <div class="row">
        <div class="col-md-6">
          <label for="cost" class="form-label">Cost</label>
          <input
            type="number"
            id="cost"
            class="form-control"
            name="cost"
            [(ngModel)]="newsLetter.cost"
            #cost="ngModel"
            placeholder="Werbekosten in Euro"
            [disabled]="readonly()"
          />
        </div>
        <div class="col-md-6">
          <label for="plannedSalesAmount" class="form-label">Planumsatz</label>
          <input
            type="number"
            id="plannedSalesAmount"
            class="form-control"
            name="plannedSalesAmount"
            [(ngModel)]="newsLetter.plannedSalesAmount"
            #plannedSalesAmount="ngModel"
            placeholder="Planumsatz in Euro"
            [disabled]="readonly()"
          />
        </div>
      </div>
    </div>

    <div class="mb-3">
      <h3 class="mb-3">Referrers</h3>
      <div class="row">
        <div class="col-md-6">
          @if (!newsLetter?.referrer?.length) {
            <p class="text-muted">Keine Referrer verfügbar.</p>
          }

          @if (newsLetter?.referrer?.length) {
            <div class="table-responsive">
              <table class="table table-bordered table-hover rounded-3">
                <thead class="table-light">
                <tr>
                  <th>Name</th>
                  <th>Typ</th>
                </tr>
                </thead>
                <tbody>
                  @for (referrer of newsLetter.referrer ; track $index) {
                    <tr>
                      <td>{{ referrer.name }}</td>
                      <td>{{ referrer.type}}</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          }

          @if (userRoles().includes('ADMIN') && !readonly()) {
            <button
              class="btn btn-outline-primary mt-3"
              (click)="openReferrerModal()"
            >
              Referrer Verwalten
            </button>
          }
        </div>
      </div>
    </div>

    <div class="mb-3">
      <h3>Sonstiges</h3>
      <div class="row g-3">
        <!-- Auflage (Number of Copies) -->
        <div class="col-md-3">
          <label for="numberOfCopies" class="form-label">Auflage</label>
          <input
            type="number"
            id="numberOfCopies"
            class="form-control"
            name="numberOfCopies"
            [(ngModel)]="newsLetter.numberOfCopies"
            placeholder="Auflage eingeben"
            #numberOfCopies="ngModel"
            [disabled]="readonly()"
          />
        </div>

        <div class="col-md-3">
          <label for="numberOfPages" class="form-label">Seiten</label>
          <input
            type="number"
            id="numberOfPages"
            class="form-control"
            name="numberOfPages"
            [(ngModel)]="newsLetter.numberOfPages"
            placeholder="Seitenanzahl eingeben"
            #numberOfPages="ngModel"
            [disabled]="readonly()"
          />
        </div>

        <div class="col-md-3">
          <label for="language" class="form-label">Sprache</label>
          <select
            id="language"
            class="form-select"
            name="language"
            [(ngModel)]="newsLetter.language"
            #language="ngModel"
            [disabled]="readonly()"
          >
            <option [ngValue]="null" disabled selected>Bitte auswählen</option>
            @if (languageLookup.length > 0) {
              @for (language of languageLookup; track $index) {
                <option [value]="language">{{ language }}</option>
              }
            }
          </select>
        </div>

        <div class="col-md-3">
          <label for="brand" class="form-label">Brand</label>
          <input
            type="text"
            id="brand"
            class="form-control"
            name="brand"
            [(ngModel)]="newsLetter.brand"
            placeholder="Markenname eingeben"
            #brand="ngModel"
            [disabled]="readonly()"
          />
        </div>
        <div class="col-md-12">
          <label for="remark" class="form-label">Notizen</label>
          <input
            type="text"
            id="remark"
            class="form-control"
            name="remark"
            [(ngModel)]="newsLetter.remark"
            placeholder="Notizen hinzufügen"
            #remark="ngModel"
            [disabled]="readonly()"
          />
        </div>

        <div class="row g-3">
          <div class="col-md-6">
            <div class="card text-center p-4">
              <h5 class="card-title">Cover Bild</h5>
              @if (!imagePreview()) {
                <p class="card-text text-muted">Noch kein Bild hinterlegt</p>
              }
              @if (imagePreview()) {
                <img
                  [src]="imagePreview()"
                  alt="Cover Preview"
                  class="img-fluid rounded mb-3"
                  style="max-height: 200px;"
                />
              }
              <input
                type="file"
                id="coverImage"
                class="form-control d-none"
                accept="image/*"
                (change)="onImageSelected($event)"
                [disabled]="readonly()"
              />
              <label for="coverImage"  class="btn btn-outline-primary"
                     [class.disabled]="readonly()"
                     [attr.aria-disabled]="readonly() ? 'true' : null">Bild auswählen</label>
              @if (userRoles().includes('ADMIN')) {
                <button
                  type="button"
                  class="btn btn-success mt-3"
                  (click)="uploadCoverImage()"
                  [disabled]="!selectedImage()"
                >
                  Bild speichern
                </button>
              }
            </div>
          </div>
        </div>


      </div>
    </div>
    <div class="d-flex justify-content--center mt-4">
      @if (userRoles().includes('ADMIN') && !readonly()) {
        <button type="submit" class="btn btn-outline-primary me-3" [disabled]="catalogueForm.invalid">
          Speichern
        </button>
      }
    </div>
  </form>
}@else {
  <div class="d-flex justify-content-center align-items-center vh-100">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
}


