import {
  Component,
  DestroyRef,
  inject,
  input,
  InputSignal,
  OnInit,
  output,
  OutputEmitterRef,
  signal,
  TemplateRef, ViewChild,
  WritableSignal
} from '@angular/core';
import {AdvertisingMediumLanguage, AdvertisingMediumStatus} from "../../domain/enum.model";
import {CatalogueService} from "../../services/catalogue.service";
import {Catalogue, CatalogueType, advertisingMediumTargetGroups} from "../../domain/catalogue.model";
import {FormsModule, NgForm} from "@angular/forms";
import {AdvertisingMedium} from "../../domain/advertisingMedium";
import {Campaign} from "../../domain/campaign.model";
import {CampaignService} from "../../services/campaign.service";
import {Utf8DecoderService} from "../../services/utf8-decoder.service";
import {ModalDismissReasons, NgbDateStruct, NgbInputDatepicker, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DxiItemModule} from "devextreme-angular/ui/nested";
import {AuthService} from "../../auth/auth.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {AdvertisementEditUtilityService} from "../../services/advertisement-edit-utility.service";
import {AdvertisingMediumService} from "../../services/advertising-medium.service";
import {TargetGroupService} from "../../services/target-group.service";
import {TargetGroup} from "../../domain/targetGroup.model";
import {Logger} from "@azure/msal-browser";

@Component({
  selector: 'app-catalogue-edit',
  templateUrl: './catalogue-edit.component.html',
  standalone: true,
  imports: [FormsModule, NgbInputDatepicker, DxiItemModule],
  styleUrls: ['./catalogue-edit.component.scss']
})
export class CatalogueEditComponent implements OnInit {
  public catalogueId: InputSignal<string> = input.required<string>();
  public handleStatus: OutputEmitterRef<{ message: string; type: string }> = output<{ message: string; type: string }>();
  public pageTitle: OutputEmitterRef<string> = output<string>();
  public imagePreview: WritableSignal<string | ArrayBuffer | null> = signal<string | ArrayBuffer | null>(null);
  public selectedImage: WritableSignal<File | null> = signal<File | null>(null);
  public readonly: WritableSignal<boolean> = signal<boolean>(true);
  public userRoles: WritableSignal<string[]> = signal<string[]>([]);
  public adTargetGroups: WritableSignal<advertisingMediumTargetGroups[]> = signal<advertisingMediumTargetGroups[]>([]);
  public catalogue!: Catalogue;

  public startDateStruct!: NgbDateStruct;
  public endDateStruct!: NgbDateStruct;

  public languageLookup: AdvertisingMediumLanguage[] = [AdvertisingMediumLanguage.DE, AdvertisingMediumLanguage.CH, AdvertisingMediumLanguage.AT, AdvertisingMediumLanguage.FR];
  public statusLookup: AdvertisingMediumStatus[] = [AdvertisingMediumStatus.ABGESCHLOSSEN, AdvertisingMediumStatus.GEPLANT, AdvertisingMediumStatus.LAUFEND];
  public catalogueTypeLookup: CatalogueType[] = [];
  public previousAdvMLookup: AdvertisingMedium[] = [];
  public campaignLookup: Campaign[] = [];

  private destroyRef: DestroyRef = inject(DestroyRef);
  public availableTargetGroups: WritableSignal<TargetGroup[]> = signal<TargetGroup[]>([]);
  @ViewChild('addTargetGroupForm') addTargetGroupForm!: NgForm;


  constructor(
    private catService: CatalogueService,
    private campService: CampaignService,
    private utf8Decoder: Utf8DecoderService,
    private appRoles: AuthService,
    private adMediumEditService: AdvertisementEditUtilityService,
    private advertisingMediumService: AdvertisingMediumService,
    private targetGroupService: TargetGroupService,
    private modalService: NgbModal) {}

  ngOnInit(): void {
    this.catService.getCatalogues().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(catalogues => {
      this.previousAdvMLookup = catalogues
        .map((catalogue) => ({
          ...catalogue,
          name: this.utf8Decoder.decodeString(catalogue?.name),
          description: this.utf8Decoder.decodeString(catalogue?.description),
        }))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    })
    this.targetGroupService.getTargetGroups().pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (targetGroups: TargetGroup[]) => {
        this.availableTargetGroups.set([...targetGroups])
      },
      error: error => {},
    })

    if (this.catalogueId()) {
      this.catService.getCatalogueById(this.catalogueId()).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: (catalogue: Catalogue) => {
          this.catalogue = { ...catalogue,
            name: this.utf8Decoder.decodeString(catalogue.name),
            description: this.utf8Decoder.decodeString(catalogue.description),
            externalID: this.utf8Decoder.decodeString(catalogue.externalID),
            remark: this.utf8Decoder.decodeString(catalogue.remark),
            supplementOf: this.utf8Decoder.decodeString(catalogue.supplementOf),
            brand: this.utf8Decoder.decodeString(catalogue.brand),
            catalogueType: {
              id: catalogue.catalogueType.id,
              name: this.utf8Decoder.decodeString(catalogue.catalogueType?.name),
            },
            campaign: {
              ... catalogue.campaign,
              name: this.utf8Decoder.decodeString(catalogue.campaign?.name),
              description: this.utf8Decoder.decodeString(catalogue.campaign?.description),
            },
            previousAdvertisingMedium: {
              ...catalogue.previousAdvertisingMedium,
              name: this.utf8Decoder.decodeString(catalogue.previousAdvertisingMedium?.name),
              description: this.utf8Decoder.decodeString(catalogue.previousAdvertisingMedium?.description),
            },
            start: new Date(catalogue.start),
            end: new Date(catalogue.end),
          };
          this.startDateStruct = this.adMediumEditService.parseDateToStruct(this.catalogue.start);
          this.endDateStruct = this.adMediumEditService.parseDateToStruct(this.catalogue.end);
          this.fetchDropdownData();
          this.loadCoverImage();
          this.pageTitle.emit(this.catalogue.description);
        },
        error: (error) => {
          const message = `Catalogue mit der ID ${this.catalogueId()} wurde nicht gefunden.`;
          this.handleStatus.emit({message: message, type: 'Info'});
        }
      })

      this.loadAdvertisingMediumTargetGroup();
    }

    const roles: string[] =
      (this.appRoles.roles.value.filter(role => role.trim().length > 0).length > 0)
        ? this.appRoles.roles.value
        : this.appRoles.getStoredRoles().filter(role => role.trim().length > 0);

    this.userRoles.set(roles);
  }

  toggleReadonly(): void {
    this.readonly.update(current => !current);
  }

  fetchDropdownData(): void {
    this.catService.getCatalogueTypes().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.catalogueTypeLookup= value;
      if (this.catalogue?.catalogueType) {
        if (Array.isArray(this.previousAdvMLookup)) {
          this.catalogue.catalogueType = this.catalogueTypeLookup.find(
            (type) => type.id === (this.catalogue.catalogueType as CatalogueType).id
          ) as CatalogueType;
        }
      }

      this.campService.getCampaigns().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
        this.campaignLookup = value;
        if(this.catalogue?.campaign) {
          this.catalogue.campaign = this.campaignLookup.find(
            (campaign) => campaign.id == (this.catalogue.campaign as Campaign).id
          ) as Campaign;
        }
      })

      if (this.catalogue.previousAdvertisingMedium && Array.isArray(this.previousAdvMLookup)) {
        this.catalogue.previousAdvertisingMedium = this.previousAdvMLookup.find(
          (medium: AdvertisingMedium) => medium.id === this.catalogue.previousAdvertisingMedium.id
        ) as AdvertisingMedium;
      }
    });
  }

  onDateSelect(dateStruct: NgbDateStruct, field: 'start' | 'end') {
    this.catalogue[field] = this.adMediumEditService.convertToDate(dateStruct);
  }

  onImageSelected(event: Event): void {
    this.adMediumEditService.handleImageSelection(event, this.selectedImage, this.imagePreview);
  }

  uploadCoverImage(): void {
    if (this.selectedImage && this.catalogue) {
      const selectedImage = this.selectedImage();
      if(selectedImage) {
      this.catService.uploadImage(this.catalogue, selectedImage).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: () => {
          const message = "Das Bild wurde erfolgreich hochgeladen.";
          this.handleStatus.emit({message: message, type: 'Success'});
          this.loadCoverImage();
        },
        error: (err) => {
          const message = "Fehler beim Hochladen des Bildes.";
          this.handleStatus.emit({message: message, type: 'Info'});
        },
      });
      }
    } else {
      const message = "Bitte wählen Sie ein Bild aus, bevor Sie es hochladen.";
      this.handleStatus.emit({message: message, type: 'Info'});
    }
  }

  loadCoverImage(): void {
    if (this.catalogue) {
      this.catService.getImage(this.catalogue).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: (imageData) => {
          if (imageData) {
            this.imagePreview.set(`data:image/jpeg;base64,${imageData}`);
          } else {
            this.imagePreview.set(null);
          }
        },
        error: (err) => {
          const message = "Fehler beim Laden des Bildes.";
          this.handleStatus.emit({message: message, type: 'Nope'});
        },
      });
    }
  }

  onSubmit(form: NgForm): void {
    if (form.valid) {
      this.adMediumEditService.prepareSubmission( this.catalogue,  this.startDateStruct, this.endDateStruct, '.Catalogue');
      this.catService.updateCatalogue(this.catalogue).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: () => {
          const message = "Die Änderungen wurden erfolgreich übernommen";
          this.handleStatus.emit({message: message, type: 'Success'});
        },
        error: (err) => {
          const message = "Etwas ist schief gelaufen";
          this.handleStatus.emit({message: message, type: 'Info'});        },
      });
    }
  }

  private loadAdvertisingMediumTargetGroup(){
    this.advertisingMediumService.getAdvertisingMediumTargetGroups(+this.catalogueId()).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (adMediums) => {
        this.adTargetGroups.set([...adMediums?.advertisingMediumTargetGroups]);
      },
      error: (error) => {
      }
    })
  }

  updateTargetGroup(item: advertisingMediumTargetGroups): void {
    item.id.targetGroupId = +item.id.targetGroupId;
    this.advertisingMediumService.updateAdvertisingMediumTargetGroup(item).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (response) => {
        this.loadAdvertisingMediumTargetGroup();
      },
      error: (error) => {
      }
    });
  }

  removeTargetGroup(item: advertisingMediumTargetGroups) {
    console.clear();
    this.advertisingMediumService.removeAdvertisingMediumTargetGroup(item.id).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (response) => {
        this.loadAdvertisingMediumTargetGroup();
      },
      error: (error) => {
      }
    });
  }

  addTargetGroup(content: TemplateRef<any>): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        if (result === 'save') {
          if (this.addTargetGroupForm.valid) {
            const formData = this.addTargetGroupForm.value;
            const newTargetGroupData: advertisingMediumTargetGroups = {
              id: {
                advertisingMediumId: +this.catalogueId(),
                targetGroupId: +formData.targetGroupId,
              },
              quantity: +formData.quantity,
            };

            this.advertisingMediumService.addAdvertisingMediumTargetGroup(newTargetGroupData).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
              next: (response) => {
                this.loadAdvertisingMediumTargetGroup();
                this.addTargetGroupForm.reset();
              },
              error: (error) => {
              },
            });
          }
        }
      }
    );
  }
}
