<h2 class="display-6 mb-5">channel online</h2>
<div class="row">
  <div class="col-12">
    <app-kpi-chart *ngIf="avm" [avm]="avm" [showNegative]="false" [perpective]="KpiPerspective.OVERVIEW" [channel]="IntakeChannel.WEB"></app-kpi-chart>
  </div>
</div>
<div>For this view, a web share of 40% was assumed and a specific seasonal curve was created. </div>
<pre>
+----+-----------+
|year| share_web |
+----+-----------+
|2020|0.309189   |
|2021|0.339524   |
|2022|0.378604   |
|2023|0.381144   |
+----+-----------+
</pre>
