import {
  Component,
  DestroyRef, inject,
  input,
  InputSignal,
  OnInit,
  output,
  OutputEmitterRef,
  signal,
  WritableSignal
} from '@angular/core';
import {MailingService} from "../../services/mailing.service";
import {CampaignService} from "../../services/campaign.service";
import {Mailing} from "../../domain/mailing.model";
import {FormsModule, NgForm} from "@angular/forms";
import {NgbDateStruct, NgbInputDatepicker} from "@ng-bootstrap/ng-bootstrap";
import {AdvertisingMediumLanguage, AdvertisingMediumStatus} from "../../domain/enum.model";
import {AdvertisingMedium} from "../../domain/advertisingMedium";
import {Campaign} from "../../domain/campaign.model";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {Utf8DecoderService} from "../../services/utf8-decoder.service";
import {AuthService} from "../../auth/auth.service";
import {AdvertisementEditUtilityService} from "../../services/advertisement-edit-utility.service";

@Component({
  selector: 'app-mailing-edit',
  standalone: true,
  imports: [
    FormsModule,
    NgbInputDatepicker
  ],
  templateUrl: './mailing-edit.component.html',
  styleUrl: './mailing-edit.component.scss'
})
export class MailingEditComponent implements OnInit{
  public mailingId: InputSignal<string> = input.required<string>();
  public handleStatus: OutputEmitterRef<{ message: string; type: string }> = output<{ message: string; type: string }>();
  public pageTitle: OutputEmitterRef<string> = output<string>();
  public imagePreview: WritableSignal<string | ArrayBuffer | null> = signal<string | ArrayBuffer | null>(null);
  public selectedImage: WritableSignal<File | null> = signal<File | null>(null);
  public readonly: WritableSignal<boolean> = signal<boolean>(true);
  public userRoles: WritableSignal<string[]> = signal<string[]>([]);
  public mail!: Mailing;

  public startDateStruct!: NgbDateStruct;
  public endDateStruct!: NgbDateStruct;

  public languageLookup: AdvertisingMediumLanguage[] = [AdvertisingMediumLanguage.DE, AdvertisingMediumLanguage.CH, AdvertisingMediumLanguage.AT, AdvertisingMediumLanguage.FR];
  public statusLookup: AdvertisingMediumStatus[] = [AdvertisingMediumStatus.ABGESCHLOSSEN, AdvertisingMediumStatus.GEPLANT, AdvertisingMediumStatus.LAUFEND];
  public previousAdvMLookup: AdvertisingMedium[] = [];
  public campaignLookup: Campaign[] = [];
  private destroyRef: DestroyRef = inject(DestroyRef);


  constructor (private mailService: MailingService, private campService: CampaignService,     private utf8Decoder: Utf8DecoderService,
               private appRoles: AuthService, private adMediumEditService: AdvertisementEditUtilityService) {}

  ngOnInit() {
    this.mailService.getMailings().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(mails => {
      this.previousAdvMLookup = mails
        .map((mails) => ({
          ...mails,
          name: this.utf8Decoder.decodeString(mails?.name),
          description: this.utf8Decoder.decodeString(mails?.description),
        }))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    })
    if(this.mailingId()){
      this.mailService.getMailingById(this.mailingId()).subscribe({
        next: (mail: Mailing) => {
          this.mail = {...mail,
          name: this.utf8Decoder.decodeString(mail.name),
          description: this.utf8Decoder.decodeString(mail.description),
          status: this.utf8Decoder.decodeString(mail.status),
          topic: this.utf8Decoder.decodeString(mail.topic),
          coupon: this.utf8Decoder.decodeString(mail.coupon),
          discount: this.utf8Decoder.decodeString(mail.discount),
          campaign: {
            ... mail.campaign,
            name: this.utf8Decoder.decodeString(mail.campaign?.name),
            description: this.utf8Decoder.decodeString(mail.campaign?.description),
          },
          start: new Date(mail.start),
          end: new Date(mail.end),
        };

        this.startDateStruct = this.adMediumEditService.parseDateToStruct(this.mail.start);
        this.endDateStruct = this.adMediumEditService.parseDateToStruct(this.mail.end);
        this.fetchDropdownData();
        this.loadCoverImage();
        this.pageTitle.emit(this.mail.description);
        },
        error: (error) => {
          const message = `Mail mit der ID ${this.mailingId()} wurde nicht gefunden.`;
          this.handleStatus.emit({message: message, type: 'Info'});
        }
      })
      const roles: string[] =
        (this.appRoles.roles.value.filter(role => role.trim().length > 0).length > 0)
          ? this.appRoles.roles.value
          : this.appRoles.getStoredRoles().filter(role => role.trim().length > 0);

      this.userRoles.set(roles);
    }
  }


  toggleReadonly(): void {
    this.readonly.update(current => !current);
  }

  fetchDropdownData(): void {
      this.campService.getCampaigns().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
        this.campaignLookup = value;
        if(this.mail?.campaign) {
          this.mail.campaign = this.campaignLookup.find(
            (campaign) => campaign.id == (this.mail.campaign as Campaign).id
          ) as Campaign;
        }
      })
      if (this.mail.previousAdvertisingMedium && Array.isArray(this.previousAdvMLookup)) {
        this.mail.previousAdvertisingMedium = this.previousAdvMLookup.find(
          (medium: AdvertisingMedium) => medium.id === this.mail.previousAdvertisingMedium.id
        ) as AdvertisingMedium;
      }
  }

  onDateSelect(dateStruct: NgbDateStruct, field: 'start' | 'end') {
    this.mail[field] = this.adMediumEditService.convertToDate(dateStruct);
  }

  onImageSelected(event: Event): void {
    this.adMediumEditService.handleImageSelection(event, this.selectedImage, this.imagePreview);
  }

  uploadCoverImage(): void {
    if (this.selectedImage && this.mail) {
      const selectedImage = this.selectedImage();
      if(selectedImage) {
        this.mailService.uploadImage(this.mail, selectedImage).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
          next: () => {
            const message = "Das Bild wurde erfolgreich hochgeladen.";
            this.handleStatus.emit({message: message, type: 'Success'});
            this.loadCoverImage();
          },
          error: () => {
            const message = "Fehler beim Hochladen des Bildes.";
            this.handleStatus.emit({message: message, type: 'Info'});
          },
        });
      }
    } else {
      const message = "Bitte wählen Sie ein Bild aus, bevor Sie es hochladen.";
      this.handleStatus.emit({message: message, type: 'Info'});
    }
  }

  loadCoverImage(): void {
    if (this.mail) {
      this.mailService.getImage(this.mail).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: (imageData) => {
          if (imageData) {
            this.imagePreview.set(`data:image/jpeg;base64,${imageData}`);
          } else {
            this.imagePreview.set(null);
          }
        },
        error: (err) => {
          const message = "Fehler beim Laden des Bildes.";
          this.handleStatus.emit({message: message, type: 'Nope'});
        },
      });
    }
  }

  onSubmit(form: NgForm): void {
    if (form.valid) {
      this.adMediumEditService.prepareSubmission(this.mail,  this.startDateStruct, this.endDateStruct, '.Mailing');
      this.mailService.updateMailing(this.mail).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: () => {
          const message = "Die Änderungen wurden erfolgreich übernommen";
          this.handleStatus.emit({message: message, type: 'Success'});
        },
        error: (err) => {
          const message = "Etwas ist schief gelaufen";
          this.handleStatus.emit({message: message, type: 'Info'});        },
      });
    }
  }
}
