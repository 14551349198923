import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChartSettingService {
  kumulierteDarstellung =false;
  options:Zeitraum[] = [
    {label: 'Annual overview', value: 'annual', dateRange: this.daterangeForOption('annual')},
    {label: 'Two months view', value: 'two_month', dateRange: this.daterangeForOption('two_month')},
    {label: 'Current and previous year', value: 'current_previous', dateRange: this.daterangeForOption('current_previous')},
    {label: 'Current and next year', value: 'current_next', dateRange: this.daterangeForOption('current_next')}
  ];


  private _rangeSubject$ = new BehaviorSubject({label: 'Two months view', value: 'two_month', dateRange: this.daterangeForOption('two_month')});
  private _kumuliertSubject$ = new BehaviorSubject(false);

  constructor() {
  }
  public rangeSubject$(): Observable<Zeitraum> { return this._rangeSubject$.asObservable(); }

  public setRangeSubject$(optionValue: string){
    let z = this.options.find(option => {
      return option.value === optionValue;
    })
    if(!z){
      z={label: 'Two months view', value: 'two_month', dateRange: this.daterangeForOption('two_month')}
    }
    this._rangeSubject$.next(z)
  }

  public getKumuliertSubject$(): Observable<boolean>{
    return this._kumuliertSubject$.asObservable();
 }
  public setKumuliertSubject$(kumuliert: boolean){
    this._kumuliertSubject$.next(kumuliert);
  }
  public get avaibleOptions(){

      return this.options;
  }


  private daterangeForOption(optionType: string): DateRange
  {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const date = new Date();
    let startDate;
    let endDate;

    switch (optionType) {
      case 'two_month':
        startDate = new Date(date);
        startDate.setMonth(date.getMonth() - 1);
        endDate = new Date(date);
        endDate.setMonth(date.getMonth() + 1);
        break;
      case 'current_previous':
        startDate = new Date(currentYear - 1, 0, 1);
        endDate = new Date(currentYear, 11, 31);
        break;
      case 'current_next':
        startDate = new Date(currentYear, 0, 1);
        endDate = new Date(currentYear + 1, 11, 31);
        break;
      default:
        startDate = new Date(currentYear, 0, 1);
        endDate = new Date(currentYear, 11, 31);
    }

    return this.createRange(startDate, endDate);
  }

  private createRange(startDate: Date, endDate: Date): DateRange {
    return {von: startDate, bis: endDate};
  }
}

export interface DateRange {
  von:Date;
  bis:Date;
}

export interface Zeitraum {
  label: string;
  value: string;
  dateRange: DateRange;
}
