import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {CatalogueService} from '../../services/catalogue.service';

import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import {lastValueFrom} from 'rxjs';
import {Catalogue, CatalogueType} from '../../domain/catalogue.model';
import {AdvertisingMediumLanguage, AdvertisingMediumStatus} from "../../domain/enum.model";
import {AdvertisingMedium} from "../../domain/advertisingMedium";
import { Status, StatusType, FeedbackComponent } from '../interaction/feedback/feedback.component';
import {Campaign} from "../../domain/campaign.model";
import {CampaignService} from "../../services/campaign.service";
import { DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoEditingModule, DxoFormModule, DxiItemModule, DxoLabelModule, DxiColumnModule, DxiButtonModule, DxiValidationRuleModule, DxoLookupModule, DxoMasterDetailModule, DxoPagingModule, DxoPagerModule } from 'devextreme-angular/ui/nested';
import { DxTemplateModule } from 'devextreme-angular/core';
import { CoverImageComponent } from '../cover-image/cover-image.component';
import { VersionDetailComponent } from '../version-detail/version-detail.component';
import {Utf8DecoderService} from "../../services/utf8-decoder.service";
import {Router, RouterLink} from "@angular/router";


@Component({
    selector: 'app-catalogue',
    templateUrl: './catalogue.component.html',
    standalone: true,
  imports: [FeedbackComponent, DxDataGridModule, DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoEditingModule, DxoFormModule, DxiItemModule, DxoLabelModule, DxiColumnModule, DxiButtonModule, DxiValidationRuleModule, DxoLookupModule, DxTemplateModule, CoverImageComponent, DxoMasterDetailModule, VersionDetailComponent, DxoPagingModule, DxoPagerModule, RouterLink]
})
export class CatalogueComponent implements OnInit {

  // @ts-ignore
  @ViewChild(DxDataGridComponent) catGrid: DxDataGridComponent;
  public dataSource: DataSource;
  public languageLookup: AdvertisingMediumLanguage[] = [AdvertisingMediumLanguage.DE, AdvertisingMediumLanguage.CH, AdvertisingMediumLanguage.AT, AdvertisingMediumLanguage.FR];
  public statusLookup: AdvertisingMediumStatus[] = [AdvertisingMediumStatus.ABGESCHLOSSEN, AdvertisingMediumStatus.GEPLANT, AdvertisingMediumStatus.LAUFEND];
  public catalogueTypeLookup: CatalogueType[] = [];
  public previousAdvMLookup: AdvertisingMedium[] = [];
  public campaignLookup: Campaign[] = [];
  // @ts-ignore
  public status: Status = undefined;

  protected router: Router = inject(Router);

  constructor(private catService: CatalogueService, private campService: CampaignService, private utf8Decoder: Utf8DecoderService) {
    this.dataSource = new DataSource({
      key: 'id',
      load: () => {
        return lastValueFrom(this.catService.getCatalogues()).then((catalogues: Catalogue[]) => {
          this.catService.getCatalogueTypes().subscribe(value => {
            this.catalogueTypeLookup= value;
            this.campService.getCampaigns().subscribe(value => {
              this.campaignLookup = value;
            })
          })
          this.previousAdvMLookup = catalogues
            .map((catalogue) => ({
              ...catalogue,
              name: this.utf8Decoder.decodeString(catalogue?.name),
              description: this.utf8Decoder.decodeString(catalogue?.description),
            }))
            .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

          catalogues = catalogues.map((catalogue) => ({
            ...catalogue,
            name: this.utf8Decoder.decodeString(catalogue.name),
            description: this.utf8Decoder.decodeString(catalogue.description),
            externalID: this.utf8Decoder.decodeString(catalogue.externalID),
            remark: this.utf8Decoder.decodeString(catalogue.remark),
            supplementOf: this.utf8Decoder.decodeString(catalogue.supplementOf),
            brand: this.utf8Decoder.decodeString(catalogue.brand),
          }));
          return {
            data: catalogues,
            totalCount: catalogues.length
          };
        }).catch((err) => {
          throw Error(err.message);
        });
      },
      insert: (catalogue: Catalogue) => {
        const _catalogue = { ...catalogue };
        // @ts-ignore
        delete _catalogue['__KEY__'];
        if(_catalogue.previousAdvertisingMedium) {
          _catalogue.previousAdvertisingMedium._class=".Catalogue";
        }
        _catalogue._class=".Catalogue";

        return lastValueFrom(this.catService.createCatalogue(_catalogue)).then((newCatalogue) => {
          this.status = new Status('Der Katalog wurde erfolgreich angelegt', StatusType.Success);
          //this.catId = newCatalogue.id;
          return newCatalogue;
        }).catch((err) => {
          throw err;
        });
      },
      update: (key: number, _new: Catalogue) => {
        return this.catGrid.instance.byKey(key).then((catalogue) => {
          const _catalogue = { ...catalogue };
          // @ts-ignore
          delete _catalogue['__KEY__'];
          if(_catalogue.previousAdvertisingMedium) {
            _catalogue.previousAdvertisingMedium._class=".Catalogue";
          }
          _catalogue._class=".Catalogue";
          delete _catalogue.coverImage;
          return lastValueFrom(this.catService.updateCatalogue(_catalogue)).then(() => {
           this.status = new Status('Die Änderungen wurde erfolgreich übernommen', StatusType.Success);
          }).catch((err) => {
            throw Error(err.message);
          });
        });
      },
      remove: (key: number) => {
        return lastValueFrom(this.catService.deleteCatalogue(key)).then(() => {
          this.status = new Status('Der Katalog wurde erfolgreich gelöscht', StatusType.Success);
        }).catch((err) => {
          throw Error( err.error.message);
        });
      }
    });
  }

  ngOnInit(): void {
  }
}
