import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Catalogue, CatalogueType} from '../domain/catalogue.model';



@Injectable({providedIn: 'root'})
export class CatalogueService {
  constructor(private httpClient: HttpClient) {
  }
  getCatalogues(): Observable<Catalogue[]>  {
    return this.httpClient.get<Catalogue[]>(`api/catalogues`);
  }

  getCatalogueById(catalogueId: string): Observable<Catalogue>  {
    return this.httpClient.get<Catalogue>(`api/catalogues/${catalogueId}`);
  }

  createCatalogue(catalogue: Catalogue): Observable<Catalogue> {
     return this.httpClient.post<Catalogue>(`api/catalogues`, catalogue);
  }

  updateCatalogue(catalogue: Catalogue): Observable<Catalogue> {
    return this.httpClient.put<Catalogue>(`api/catalogues`, catalogue);
  }

  uploadImage(catalogue: Catalogue, file: File): Observable<any> {
    const myFormData: FormData = new FormData();
    myFormData.append('file', file, file.name);
    return this.httpClient.post(`api/advertisingMediums/${catalogue.id}/images/upload-image`, myFormData, {reportProgress: true});
  }

  getImage(catalogue: Catalogue): Observable<any> {
    return this.httpClient.get<any>(`api/advertisingMediums/${catalogue.id}/images/get-image`);
  }

  deleteCatalogue(key: number): Observable<any> {
    return this.httpClient.delete<any>(`api/catalogues/${key}`);
  }

  //
  getCatalogueTypes(): Observable<CatalogueType[]>  {
    return this.httpClient.get<CatalogueType[]>(`api/catalogue-type`);
  }
}
