<h2>
  Die Windows-Credentials werden abgefragt.<br />Falls möglich erfolgt die
  Weiterleitung in die Anwendung
</h2>

<h1>Profile</h1>

<button (click)="addTargetGroup()">Add</button>

<button (click)="deleteTargetGroup()">Remove</button>

<p>Dein Profil</p>
<pre>{{ profile | json }}</pre>
