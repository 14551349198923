import {Component, effect, input, InputSignal, signal, WritableSignal} from '@angular/core';
import {formatCurrency} from "@angular/common";
import {NgxEchartsDirective, provideEcharts} from "ngx-echarts";

@Component({
  selector: 'app-catalogue-bar-chart',
  standalone: true,
  imports: [NgxEchartsDirective],
  templateUrl: './catalogue-bar-chart.component.html',
  styleUrl: './catalogue-bar-chart.component.scss',
  providers: [provideEcharts()]
})
export class CatalogueBarChartComponent {
  kpiChartDTO: InputSignal<any> = input.required<any>();
  kpiOptions: WritableSignal<any> = signal<any>(null);

  constructor() {
    effect(() => {
      this.preparedChat(this.kpiChartDTO());
    }, { allowSignalWrites: true });
  }

  private preparedChat(chartDTO: any) {
    if (chartDTO) {
      const head: any[] = [...chartDTO.xaxisData];
      head.unshift('data');

      const data: any[] = [head];
      const seriesLayout: any[] = [];

      chartDTO.kpiSeries.forEach((serie: any) => {
        const d: any = [...serie.data];
        d.unshift(serie.name);
        data.push(d);

        for (let i = 1; i < head.length; i++) {
          seriesLayout.push({
            type: 'bar',
            name: head[i],
            emphasis: { focus: 'series' },
          });
        }
      });

      this.kpiOptions.set({
        legend: { show: true },
        tooltip: {
          trigger: 'axis',
          formatter: (params: any[]) => {
            let content = `<strong>${params[0].axisValue}</strong><br>`;
            params.forEach((param) => {
              if (param.value[param.seriesIndex + 1] > 0) {
                content += `
                  <div>
                    <span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:${param.color};margin-right:5px;"></span>
                    ${param.seriesName}: ${formatCurrency(
                  param.value[param.seriesIndex + 1],
                  'de',
                  '€',
                  'EUR',
                  '2.0-0'
                )}
                  </div>`;
              }
            });
            return content;
          },
          axisPointer: {
            type: 'shadow',
          },
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: true },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        dataset: {
          source: data,
        },
        xAxis: { type: 'category' },
        yAxis: {
          axisLabel: {
            formatter: '{value} €',
          },
        },
        series: seriesLayout,
      }) ;
    }
  }
}
