import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {Mailing} from "../domain/mailing.model";
import {Version} from "../domain/version.model";
import {Catalogue} from "../domain/catalogue.model";

@Injectable({providedIn: 'root'})
export class MailingService {

  constructor(private httpClient: HttpClient) {

  }

  getMailings(): Observable<Mailing[]> {
    return this.httpClient.get<Mailing[]>(`api/mailings`);
  }
  getMailingById(mailingsId: string): Observable<Mailing>  {
    return this.httpClient.get<Mailing>(`api/mailings/${mailingsId}`);
  }
  createMailing(mailing: Mailing): Observable<Mailing> {
    return this.httpClient.post<Mailing>(`api/mailings`, mailing);
  }
  updateMailing(mailing: Mailing): Observable<Mailing> {
    return this.httpClient.put<Mailing>(`api/mailings`, mailing);
  }
  uploadImage(mail: Mailing, file: File): Observable<any> {
    const myFormData: FormData = new FormData();
    myFormData.append('file', file, file.name);
    return this.httpClient.post(`api/advertisingMediums/${mail.id}/images/upload-image`, myFormData, {reportProgress: true});
  }
  getImage(mail: Mailing): Observable<any> {
    return this.httpClient.get<any>(`api/advertisingMediums/${mail.id}/images/get-image`);
  }

  deleteMailing(key: number): Observable<any> {
    return this.httpClient.delete<any>(`api/mailings/${key}`);
  }
  getMailingVersions(key: number | undefined): Observable<Version[]> {
    return this.httpClient.get<Version[]>(`api/advertisingMedium/${key}/versions`);
  }
}
