import {Component, OnInit} from '@angular/core';
import {NavLink} from "../../../domain/navLinks.model";
import { ActivatedRoute, RouterLink } from '@angular/router';
import {Store} from '@ngrx/store';

import {Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import { NgbCollapse, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import {MsalService} from '@azure/msal-angular';
import {AccountInfo} from '@azure/msal-common';
import {GraphUser} from '../../../domain/user.model';
import {AuthService} from "../../../auth/auth.service";

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    standalone: true,
    imports: [RouterLink, NgbCollapse, NgFor, NgIf, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbDropdownItem, AsyncPipe]
})
export class NavComponent implements OnInit{

  isMenuCollapsed: boolean;
  // @ts-ignore
  account: GraphUser;

  constructor(public route: ActivatedRoute, private authService: MsalService, private appRoles: AuthService
  ) {
    this.isMenuCollapsed = true;
     this.account = this.authService.instance.getAllAccounts()[0] as GraphUser;
  }


  public navLinks: NavLink[] = [
    {id: 1, text: "Analysis", path:"/",subLinks:[
            {text: "Overview",path:"overview", id:11, subLinks:[]},
        {text: "Order Channel",path:"order-channel", id:12, subLinks:[]},
        {text: "Online", path: "overview-online", id: 13, subLinks: []},
        {text: "Store", path: "overview-store", id: 14, subLinks: []},
        {text: "Phone", path: "overview-phone", id: 15, subLinks: []},
            {text: "Timeline",path:"overview-gantt", id:16, subLinks:[]},

        ]},
    {id: 20, text: "Advertising media", path:"", subLinks:[
        {id: 21, text: "Campaigns", path:"campaign",subLinks:[]},
        {id: 22, text: "Catalogue", path:"catalogue",subLinks:[]},
        {id: 23, text: "Mailing", path:"mailing",subLinks:[]},
        {id: 24, text: "Newsletter", path:"newsletter",subLinks:[]},
        {id: 25, text: "Event", path:"event",subLinks:[]}
      ]
    },
    {id: 30, text: "Purchase", path:"", subLinks:[
        {text: "Products", path: "purchase-products", id: 34, subLinks: [] },
      ]
    },
     {id: 40, text: "Stammdaten", path: "stammdaten", subLinks: []},
    {id: 50, text: "Settings", path:"", subLinks:[
        {text: "Loading", path: "loading-stand", id: 41, subLinks: []}
      ]},
    {id: 60, text: "Dev", path:"", subLinks:[
        {text: "Products  Kpi", path: "products-kpi", id: 61, subLinks: [] },
        {text: "Products",path:"products", id:62, subLinks:[]},
      ]}
  ]

  ngOnInit() {
    this.isMenuCollapsed = true;
  }

  logout() {
    this.appRoles.clearStoredRoles();
    this.authService.logoutRedirect();
  }

  collapseNavbar(): void {
    this.isMenuCollapsed = true;
  }

}
