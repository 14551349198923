<dx-gantt taskListWidth="500" [scaleType]="'weeks'" height="800" [rootValue]="-1"
          [startDateRange]="'2025-01-01'"
          [endDateRange]="'2025-12-31'"
          [showResources]="false"
          [firstDayOfWeek]="1"
           [headerFilter]="['start','anyof',[2025]]"
(onTaskDblClick)="taskDblClick($event)">
    <dxo-tasks [dataSource]="tasks"></dxo-tasks>


    <dxi-column dataField="title" caption="Subject" [width]="300"></dxi-column>
    <dxi-column
            dataField="start"
            caption="Start Date"
            dataType="date"
            sortOrder="asc"
    ></dxi-column>
    <dxi-column dataField="end" caption="End Date" dataType="date"></dxi-column>

    <dxo-toolbar>
        <dxi-item name="undo"></dxi-item>
        <dxi-item name="redo"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="collapseAll"></dxi-item>
        <dxi-item name="expandAll"></dxi-item>
<!--        <dxi-item name="separator"></dxi-item>-->
<!--        <dxi-item name="addTask"></dxi-item>-->
<!--        <dxi-item name="deleteTask"></dxi-item>-->
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="zoomIn"></dxi-item>
        <dxi-item name="zoomOut"></dxi-item>
    </dxo-toolbar>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-editing [enabled]="true"></dxo-editing>
</dx-gantt>
