<h2 class="display-6 mb-5">channel phone</h2>
<div class="row">
  <div class="col-12">
    <app-kpi-chart *ngIf="avm" [avm]="avm" [showNegative]="false" [perpective]="KpiPerspective.OVERVIEW" [channel]="IntakeChannel.TEL"></app-kpi-chart>
  </div>
</div>
<div>For this view, a share of 26% was assumed and a specific seasonal curve was created. </div>
<pre>
+----+----------+
|year|share_pers|
+----+----------+
|2020|0.441174  |
|2021|0.363920  |
|2022|0.313843  |
|2023|0.287936  |
+----+----------+
</pre>
