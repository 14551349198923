<div class="container mt-4">
  <div class="row mb-4">
    <div class="col text-center">
      <h1 class="display-5">Dashboard Übersicht</h1>
      <p class="lead">Navigieren Sie durch die wichtigsten Einblicke und verwalten Sie Ihre Daten effizient.</p>
    </div>
  </div>
  <div echarts style="height: 500px" [options]="kpiOptions" class="mt-5"></div>
  <div class="row g-3">
    <div class="col form-check form-switch d-flex justify-content-center">
      <div>
        <input class="form-check-input" type="checkbox" role="switch" [ngModel]="kumulierteDarstellung" (ngModelChange)="changeKumulierteDarstellung($event)" id="flexSwitchCheckDefault">
        <label class="form-check-label" for="flexSwitchCheckDefault">Cumulative presentation</label>
      </div>
    </div>

    <div  class="col btn-group" role="group" aria-label="period under review">
      <ng-container  *ngFor="let option of options; index as i">
        <input type="radio" class="btn-check"  autocomplete="off"
               [value]="option.value"
               [id]="i"
               [ngModel]="selectedOption.value"
               name="options"
               (ngModelChange)="changeBetrachtungsZeitraum($event)"
        />
        <label for="{{i}}" class="btn btn-outline-secondary">{{option.label}}</label>
      </ng-container>
    </div>
  </div>
  <div class="container mt-5">
    <div class="row g-3">
      <div class="col-md-3 d-flex">
        <div id="catalogue-section" class="p-3 bg-light border rounded shadow-sm w-100 d-flex flex-column">
          <h2><a [routerLink]="['/app/catalogue']">Katalog</a></h2>
          <p>Entdecken und verwalten Sie Kataloge, um Ihre Kampagnen zu verbessern.</p>
        </div>
      </div>
      <div class="col-md-3 d-flex">
        <div id="mailing-section" class="p-3 bg-light border rounded shadow-sm w-100 d-flex flex-column">
          <h2><a [routerLink]="['/app/mailing']">Mailing</a></h2>
          <p>Organisieren und versenden Sie gezielte Mailings effektiv.</p>
        </div>
      </div>
      <div class="col-md-3 d-flex">
        <div id="event-section" class="p-3 bg-light border rounded shadow-sm w-100 d-flex flex-column">
          <h2><a [routerLink]="['/app/event']">Event</a></h2>
          <p>Planen und verfolgen Sie Events, um Ihre Reichweite und Wirkung zu maximieren.</p>
        </div>
      </div>
      <div class="col-md-3 d-flex">
        <div id="newsletter-section" class="p-3 bg-light border rounded shadow-sm w-100 d-flex flex-column">
          <h2><a [routerLink]="['/app/newsletter']">Newsletter</a></h2>
          <p>Erstellen und verwalten Sie Newsletter, um Ihre Zielgruppe zu begeistern.</p>
        </div>
      </div>
      <div class="row g-3">
        <div class="col-md-3 d-flex">
          <div id="purchase-products-section" class="p-3 bg-light border rounded shadow-sm w-100 d-flex flex-column">
            <h2><a [routerLink]="['/app/purchase-products']">Einkauf</a></h2>
            <p>Verwalten Sie Ihre Produkteinkäufe und optimieren Sie Ihre Beschaffung effektiv.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
