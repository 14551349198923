import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {AdvertisedEvent} from "../domain/event.model";
import {EventOrt} from "../domain/eventOrt.model";
import {GridState} from '../domain/grid-state.model';
import {NotifyService} from './notify.service';

@Injectable({
  providedIn: 'root'
})
export class GridStateService {

  constructor(private httpClient: HttpClient, private notifyService : NotifyService) {
  }

  getStateByGridNameAndState(key: string,stateName: string): Observable<GridState> {
    return this.httpClient.get<GridState>(`api/grid-state/${key}/${stateName}`)
  }

  getStatesByGridName(key: string): Observable<GridState[]> {
    return this.httpClient.get<GridState[]>(`api/grid-state/${key}`)
  }

  saveState(key: string,stateName: string, state: any): Observable<any> {
    return this.httpClient.post<any>(`api/grid-state/${key}/${stateName}`, state);
  }


  deleteState(key: string,stateName: string) {
    return this.httpClient.delete<any>(`api/grid-state/${key}/${stateName}`).pipe(catchError(this.handleError));
  }
  private handleError(error: HttpErrorResponse) {
   alert(error.error.message)
    // if (error.status === 0) {
    //   // A client-side or network error occurred. Handle it accordingly.
    //   console.error('An error occurred:', error.error);
    // } else {
    //   // The backend returned an unsuccessful response code.
    //   // The response body may contain clues as to what went wrong.
    //   console.error(
    //     `Backend returned code ${error.status}, body was: `, error.error);
    // }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
