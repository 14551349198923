import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import {lastValueFrom} from 'rxjs';
import {Version} from '../../domain/version.model';
import {Router} from '@angular/router';
import {AdvertisingMediumService} from "../../services/advertising-medium.service";
import { DxDataGridComponent, DxDataGridModule } from "devextreme-angular";
import {VersionService} from "../../services/version.service";
import {NotifyService} from '../../services/notify.service';
import { NgIf } from '@angular/common';
import { DxoEditingModule, DxiColumnModule, DxiButtonModule } from 'devextreme-angular/ui/nested';

@Component({
    selector: 'detail-version',
    templateUrl: './version-detail.component.html',
    standalone: true,
    imports: [NgIf, DxDataGridModule, DxoEditingModule, DxiColumnModule, DxiButtonModule]
})
export class VersionDetailComponent implements AfterViewInit{
  @Input() key: number | undefined;
  @Input() buttons: boolean | undefined;
  // @ts-ignore
  @ViewChild(DxDataGridComponent) verGrid: DxDataGridComponent;
  versionDataSource: DataSource | undefined;
  hasVersion = true;

  constructor(private toast: NotifyService,private advService: AdvertisingMediumService, private verService: VersionService, private router: Router) {
    this.routeTo=this.routeTo.bind(this);
    this.copyVersion=this.copyVersion.bind(this);
    this.setFinal=this.setFinal.bind(this);

  }

  ngAfterViewInit() {
    this.versionDataSource = new DataSource({
      key: 'id',
      load: () => {
        return lastValueFrom(this.advService.getAdverstisingMediumVersions(this.key)).then((versions: Version[]) => {
          if(versions.length > 0){
            this.hasVersion = true;
          }
          else{
            this.hasVersion = false;
          }
          return {
            data: versions,
            totalCount: versions.length
          };
        }).catch((err) => {
          throw err;
        });
      },
      update: (key: number, _new: Version) => {
        return this.verGrid.instance.byKey(key).then((version) => {
          const _version = {...version};
          delete  _version['__KEY__'];
          return lastValueFrom(this.verService.updateVersion(_version)).then(() => {
          }).catch((err) => {
            throw Error(err.message);
          });
        });
      },
        remove: (key: number) => {
          return lastValueFrom(this.verService.deleteVersion(key)).then(() => {

          }).catch((err) => {
              throw Error(err.error.message);
          });
      }
    })
  }


  routeTo(e: any) {
    const versonId =  e.row.data.id;
    this.router.navigate(['app','version-products',versonId]);
  }

  setFinal(e: any) {
    const data: Version = e.row.data;
    data.isFinalVersion = true;
    this.verService.updateVersion(data).subscribe(value => {
      // popup versionfinal wurde geändert oder fehlermeldung
      this.versionDataSource?.reload();
    })
  }

  deleteButtonVisible(e:any){
    return e.row.data.isFinalVersion && !e.row.data.sealed;
  }

  copyButtonVisible(e:any){
    return e.row.data.isFinalVersion;
  }

  setFinalButtonVisible(e:any){
    return !e.row.data.isFinalVersion;
  }

  copyVersion(e: any) {
    const data: Version = e.row.data;
    this.verService.copyVersion(data).subscribe((value: Version) => {
      // popup versionfinal wurde geändert oder fehlermeldung
      this.versionDataSource?.reload();
    })
  }

    createEmptyVersion() {
      if(this.key) {
        this.verService.createEmptyVersion(this.key).subscribe(value => {
          this.toast.toastSucess(`Es wurde eine leere Version für den Werbeträger angelegt.`)
        });
      }else{
        this.toast.toastWarning('Keine Werbeträger-Id gefunden.')
      }

    }
  createVorschlagVersion() {
    this.toast.toastInfo('Diese Funktion wurde noch nicht implementiert.')
  }
}
