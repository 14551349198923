import {Component, DestroyRef, inject, input, InputSignal, OnInit, output, OutputEmitterRef, signal, WritableSignal} from '@angular/core';
import {NgbDateStruct, NgbInputDatepicker, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AdvertisingMediumLanguage, AdvertisingMediumStatus} from "../../domain/enum.model";
import {AdvertisingMedium} from "../../domain/advertisingMedium";
import {Campaign} from "../../domain/campaign.model";
import {CampaignService} from "../../services/campaign.service";
import {Utf8DecoderService} from "../../services/utf8-decoder.service";
import {AuthService} from "../../auth/auth.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {FormsModule, NgForm} from "@angular/forms";
import {Newsletter} from "../../domain/newsletter.model";
import {NewsletterService} from "../../services/newsletter.service";
import {NlReferrerComponent} from "../newsletter/nl-referrer/nl-referrer.component";
import {NotifyService} from "../../services/notify.service";
import {Referrer} from "../../domain/referrer.model";
import {ReferrerService} from "../../services/referrer.service";
import {AdvertisementEditUtilityService} from "../../services/advertisement-edit-utility.service";

@Component({
  selector: 'app-newsletter-edit',
  standalone: true,
  imports: [
    FormsModule,
    NgbInputDatepicker
  ],
  templateUrl: './newsletter-edit.component.html',
  styleUrl: './newsletter-edit.component.scss'
})
export class NewsletterEditComponent implements OnInit {
  public newsletterId: InputSignal<string> = input.required<string>();
  public handleStatus: OutputEmitterRef<{ message: string; type: string }> = output<{ message: string; type: string }>();
  public pageTitle: OutputEmitterRef<string> = output<string>();
  public imagePreview: WritableSignal<string | ArrayBuffer | null> = signal<string | ArrayBuffer | null>(null);
  public selectedImage: WritableSignal<File | null> = signal<File | null>(null);
  public readonly: WritableSignal<boolean> = signal<boolean>(true);
  public userRoles: WritableSignal<string[]> = signal<string[]>([]);
  public newsLetter!: Newsletter;

  public startDateStruct!: NgbDateStruct;
  public endDateStruct!: NgbDateStruct;

  public languageLookup: AdvertisingMediumLanguage[] = [AdvertisingMediumLanguage.DE, AdvertisingMediumLanguage.CH, AdvertisingMediumLanguage.AT, AdvertisingMediumLanguage.FR];
  public statusLookup: AdvertisingMediumStatus[] = [AdvertisingMediumStatus.ABGESCHLOSSEN, AdvertisingMediumStatus.GEPLANT, AdvertisingMediumStatus.LAUFEND];
  public previousAdvMLookup: AdvertisingMedium[] = [];
  public campaignLookup: Campaign[] = [];

  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private newsletterService: NewsletterService,
    private campService: CampaignService,
    private utf8Decoder: Utf8DecoderService,
    private appRoles: AuthService,
    private modalService: NgbModal,
    private notifyService: NotifyService,
    private referrerService: ReferrerService,
    private adMediumEditService: AdvertisementEditUtilityService) {}

  ngOnInit(): void {
    this.newsletterService.getNewsletter().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(newsLetters => {
      this.previousAdvMLookup = newsLetters
        .map((newsLetter) => ({
          ...newsLetter,
          name: this.utf8Decoder.decodeString(newsLetter?.name),
          description: this.utf8Decoder.decodeString(newsLetter?.description),
        }))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    })

    if (this.newsletterId()) {
      this.newsletterService.getNewsletterById(this.newsletterId()).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: (newsLetter: Newsletter) => {
          this.newsLetter = { ...newsLetter,
            name: this.utf8Decoder.decodeString(newsLetter.name),
            description: this.utf8Decoder.decodeString(newsLetter.description),
            externalID: this.utf8Decoder.decodeString(newsLetter.externalID),
            remark: this.utf8Decoder.decodeString(newsLetter.remark),
            brand: this.utf8Decoder.decodeString(newsLetter.brand),
            campaign: {
              ... newsLetter.campaign,
              name: this.utf8Decoder.decodeString(newsLetter.campaign?.name),
              description: this.utf8Decoder.decodeString(newsLetter.campaign?.description),
            },
            previousAdvertisingMedium: {
              ...newsLetter.previousAdvertisingMedium,
              name: this.utf8Decoder.decodeString(newsLetter.previousAdvertisingMedium?.name),
              description: this.utf8Decoder.decodeString(newsLetter.previousAdvertisingMedium?.description),
            },
            referrer: newsLetter.referrer?.map(ref => ({
              ...ref,
              name: this.utf8Decoder.decodeString(ref?.name),
              type: this.utf8Decoder.decodeString(ref?.type),
            }))|| [],
            start: new Date(newsLetter.start),
            end: new Date(newsLetter.end),
          };
          this.startDateStruct = this.adMediumEditService.parseDateToStruct(this.newsLetter.start);
          this.endDateStruct = this.adMediumEditService.parseDateToStruct(this.newsLetter.end);
          this.fetchDropdownData();
          this.loadCoverImage();
          this.pageTitle.emit(this.newsLetter.description);
        },
        error: (error) => {
          const message = `News Letter mit der ID ${this.newsletterId()} wurde nicht gefunden.`;
          this.handleStatus.emit({message: message, type: 'Info'});
        }
      })
    }

    const roles: string[] =
      (this.appRoles.roles.value.filter(role => role.trim().length > 0).length > 0)
        ? this.appRoles.roles.value
        : this.appRoles.getStoredRoles().filter(role => role.trim().length > 0);

    this.userRoles.set(roles);
  }

  toggleReadonly(): void {
    this.readonly.update(current => !current);
  }

  fetchDropdownData(): void {
      this.campService.getCampaigns().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
        this.campaignLookup = value;
        if(this.newsLetter?.campaign) {
          this.newsLetter.campaign = this.campaignLookup.find(
            (campaign) => campaign.id == (this.newsLetter.campaign as Campaign).id
          ) as Campaign;
        }
      })

      if (this.newsLetter.previousAdvertisingMedium && Array.isArray(this.previousAdvMLookup)) {
        this.newsLetter.previousAdvertisingMedium = this.previousAdvMLookup.find(
          (medium: AdvertisingMedium) => medium.id === this.newsLetter.previousAdvertisingMedium.id
        ) as AdvertisingMedium;
      }
  }

  onDateSelect(dateStruct: NgbDateStruct, field: 'start' | 'end') {
    this.newsLetter[field] = this.adMediumEditService.convertToDate(dateStruct);
  }

  onImageSelected(event: Event): void {
    this.adMediumEditService.handleImageSelection(event, this.selectedImage, this.imagePreview);
  }

  uploadCoverImage(): void {
    if (this.selectedImage && this.newsLetter) {
      const selectedImage = this.selectedImage();
      if(selectedImage) {
        this.newsletterService.uploadImage(this.newsLetter, selectedImage).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
          next: () => {
            const message = "Das Bild wurde erfolgreich hochgeladen.";
            this.handleStatus.emit({message: message, type: 'Success'});
            this.loadCoverImage();
          },
          error: (err) => {
            const message = "Fehler beim Hochladen des Bildes.";
            this.handleStatus.emit({message: message, type: 'Info'});
          },
        });
      }
    } else {
      const message = "Bitte wählen Sie ein Bild aus, bevor Sie es hochladen.";
      this.handleStatus.emit({message: message, type: 'Info'});
    }
  }

  loadCoverImage(): void {
    if (this.newsLetter) {
      this.newsletterService.getImage(this.newsLetter).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: (imageData) => {
          if (imageData) {
            this.imagePreview.set(`data:image/jpeg;base64,${imageData}`);
          } else {
            this.imagePreview.set(null);
          }
        },
        error: (err) => {
          const message = "Fehler beim Laden des Bildes.";
          this.handleStatus.emit({message: message, type: 'Nope'});
        },
      });
    }
  }

  openReferrerModal(): void {
    const modalRef = this.modalService.open(NlReferrerComponent);
    modalRef.componentInstance.rowData = this.newsLetter;
    modalRef.result.then(() => {
      this.notifyService.toastInfo("Referrer wurde erfolgreich bearbeitet.");
      this.refreshReferrer();
    }).catch((err) => {
      this.notifyService.toastError(`Modal wurde geschlossen: ${err.message}`)
    });
  }

  onSubmit(form: NgForm): void {
    if (form.valid) {
      this.adMediumEditService.prepareSubmission(this.newsLetter,  this.startDateStruct, this.endDateStruct, '.Newsletter');
      this.newsletterService.updateNewsletter(this.newsLetter).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: () => {
          const message = "Die Änderungen wurden erfolgreich übernommen";
          this.handleStatus.emit({message: message, type: 'Success'});
        },
        error: (err) => {
          const message = "Etwas ist schief gelaufen";
          this.handleStatus.emit({message: message, type: 'Info'});        },
      });
    }
  }

  private refreshReferrer(): void {
    const id = +this.newsletterId();
    if (!id) {
      this.notifyService.toastError("Ungültige Newsletter-ID für das Aktualisieren von Referrer.");
      return;
    }
    this.referrerService.getReferrerByNewsletterId(id).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (referrer: Referrer[]) => {
        this.newsLetter.referrer = referrer || [];
      },
      error: (err) => {
        this.notifyService.toastError(`Aktualisieren der Referrer fehlgeschlagen: ${err.message}`);
      }
    });
  }
}
