import {ChangeDetectorRef, Component, DestroyRef, effect, inject, OnInit, signal, WritableSignal} from '@angular/core';
import {CatalogueEditComponent} from "../catalogue-edit/catalogue-edit.component";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {KpiChartDTO, KpiLineChartDTO} from "../../domain/kpi.mpodel";
import {KpiService} from "../../services/kpi.service";
import {AmBarChartCompareComponent} from "../charting/am-bar-chart-compare/am-bar-chart-compare.component";
import {AmLineChartCompareComponent} from "../charting/am-line-chart-compare/am-line-chart-compare.component";
import {TitleCasePipe} from "@angular/common";
import {FeedbackComponent, Status, StatusType} from "../interaction/feedback/feedback.component";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {MailingEditComponent} from "../mailing-edit/mailing-edit.component";
import {EventEditComponent} from "../event-edit/event-edit.component";
import {NewsletterEditComponent} from "../newsletter-edit/newsletter-edit.component";
import {CatalogueBarChartComponent} from "../catalogue-bar-chart/catalogue-bar-chart.component";

@Component({
  selector: 'app-details',
  standalone: true,
  imports: [CatalogueEditComponent, AmBarChartCompareComponent, AmLineChartCompareComponent, TitleCasePipe, FeedbackComponent, RouterLink, MailingEditComponent, EventEditComponent, NewsletterEditComponent, CatalogueBarChartComponent],
  templateUrl: './details.component.html',
  styleUrl: './details.component.scss'
})
export class DetailsComponent implements OnInit {
  public id: WritableSignal<string | null > = signal<string | null>('');
  public navigationState: { [key: string]: unknown } | null = null;
  public title: WritableSignal<string> = signal<string>('');
  public advType: WritableSignal<string> = signal<string>('');
  protected previousUrl: WritableSignal<string | null> = signal<string | null>(null);

  public barChart: KpiChartDTO | undefined;
  public lineChart: KpiLineChartDTO | undefined;
  public barChartKriterium: KpiChartDTO | undefined;
  public barChartScorequantil: KpiChartDTO | undefined;
  // @ts-ignore
  public status: Status = undefined;
  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(private route: ActivatedRoute, private kpiService: KpiService, private cdr: ChangeDetectorRef, private router: Router) {}

  ngOnInit() {
    this.id.set(this.route.snapshot.paramMap.get('id'));
    this.navigationState = history.state;
    if (this.navigationState == null) {
      const navigation = this.router.getCurrentNavigation();
      this.navigationState = (navigation?.extras?.state as { [key: string]: unknown }) ?? null;
    }

    if (this.navigationState && this.navigationState['previousRoute'] && this.navigationState['selectedDataType']) {
      this.previousUrl.set((this.navigationState['previousRoute'] as string).split("/")[2] || null);
      this.advType.set(this.navigationState['selectedDataType'] as string);
    }
    this.loadPerformanceData();
  }

  handleStatus(status: { message: string, type: string }): void {
    if(status.type === 'Success') {
      this.status = new Status(status.message, StatusType.Success);
    } else if(status.type === 'Info') {
      this.status = new Status(status.message,  StatusType.Info);
    } else if (status.type === 'Nope') {
      this.status = new Status(status.message,  StatusType.Nope);
    }
    this.loadPerformanceData();
  }

  private loadPerformanceData(): void {
    const currentId = this.id();
    if (currentId) {
      this.kpiService.getKpiBarChatCompare(+currentId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: KpiChartDTO) => {
        this.barChart = { ...value };
        this.cdr.detectChanges();
      });
      this.kpiService.getKpiLineChatCompare(+currentId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: KpiLineChartDTO) => {
        this.lineChart = { ...value };
        this.cdr.detectChanges();
      });
      this.kpiService.getKriteriumKpiBarChatCompare(+currentId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: KpiChartDTO) => {
        this.barChartKriterium = { ...value };
        this.cdr.detectChanges();
      });
      this.kpiService.getScorequantilKpiBarChatCompare(+currentId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: KpiChartDTO) => {
        this.barChartScorequantil = { ...value };
        this.cdr.detectChanges();
      });
    }
  }

  setPageTitle(title: string) {
    this.title.set(title);
  }
}
