import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Catalogue} from '../domain/catalogue.model';
import {Version} from '../domain/version.model';
import {AdvertisingMediumKpi, KpiChartDTO, KpiLineChartDTO, KpiVal} from '../domain/kpi.mpodel';
import {AdvertisingMedium} from '../domain/advertisingMedium';


@Injectable({providedIn: 'root'})
export class KpiService {
  constructor(private httpClient: HttpClient) {
  }
  getKpiData(): Observable<KpiChartDTO>  {
    return this.httpClient.get<KpiChartDTO>(`api/kpi-data`);
  }


  getKpiUmsatz(von: Date, bis: Date, channel: string): Observable<KpiLineChartDTO>  {
    return this.httpClient.get<KpiLineChartDTO>(`api/kpi-umsatz-data/${von.valueOf()}/${bis.valueOf()}/${channel}`);
  }

  getKpiOrderChannelUmsatz(von: Date, bis: Date): Observable<KpiLineChartDTO>  {
    return this.httpClient.get<KpiLineChartDTO>(`api/kpi-umsatz-order-channel-data/${von.valueOf()}/${bis.valueOf()}`);
  }

  getKpiNLData(): Observable<KpiVal[]>  {
    return this.httpClient.get<KpiVal[]>(`api/kpi-nl-data`);
  }


  getKpiUmsatzVersion(): Observable<KpiChartDTO>  {
    return this.httpClient.get<KpiChartDTO>(`api/kpi-line-data/1/version-id/19`);
  }


  getKpiLineData( kpiId: number ): Observable<KpiLineChartDTO>  {
    return this.httpClient.get<KpiLineChartDTO>(`api/kpi-line-data/${kpiId}`);
  }

  getKpiBarChatCompare(advertisingMediumId: number) {
    return this.httpClient.get<KpiChartDTO>(`api/kpi-bar-char-compare/${advertisingMediumId}`);
  }

  getKriteriumKpiBarChatCompare(advertisingMediumId: number) {
    // return this.httpClient.get<KpiChartDTO>(`api/kpi-bar-char-compare/${advertisingMediumId}`);
    return this.httpClient.get<KpiChartDTO>('/main/webapp/assets/mock-data/kriterium.json');
  }

  getScorequantilKpiBarChatCompare(advertisingMediumId: number) {
    // return this.httpClient.get<KpiChartDTO>(`api/kpi-bar-char-compare/${advertisingMediumId}`);
    return this.httpClient.get<KpiChartDTO>('/main/webapp/assets/mock-data/scorequantil.json');
  }

  getKpiLineChatCompare(advertisingMediumId: number) {
    return this.httpClient.get<KpiLineChartDTO>(`api/kpi-line-chart-compare/${advertisingMediumId}`);
  }


  getLastAdmUmsatzKpis() {
    return this.httpClient.get<AdvertisingMediumKpi[]>(`api/adm-umsatz-total`);
  }
}
