import {Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AdvertisingMediumKpi, color, IntakeChannel, KpiLineChartDTO, KpiPerspective} from "../../domain/kpi.mpodel";
import {KpiChartComponent} from "../charting/kpi-chart.component";
import {formatCurrency, NgFor, NgForOf, NgIf} from "@angular/common";
import {AdvertisingMedium} from "../../domain/advertisingMedium";
import DataSource from "devextreme/data/data_source";
import {AdvertisingMediumService} from "../../services/advertising-medium.service";
import {lastValueFrom} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {EChartsOption} from "echarts";
import {ChartSettingService, Zeitraum} from "../charting/chart-setting.service";
import {KpiService} from "../../services/kpi.service";
import * as echarts from "echarts/core";
import {FormsModule} from "@angular/forms";
import {NgxEchartsDirective, provideEcharts} from "ngx-echarts";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [NgxEchartsDirective, FormsModule, NgFor, RouterLink],
  providers: [provideEcharts()],
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent implements  OnInit {

  avm: AdvertisingMedium[] | undefined;
  showNegative: boolean = false;
  perpective: KpiPerspective = KpiPerspective.OVERVIEW;
  channel: IntakeChannel = IntakeChannel.ALL;
  protected readonly KpiPerspective = KpiPerspective;
  protected readonly IntakeChannel = IntakeChannel;


  public kpiOptions: EChartsOption = {};
  public ready = false;
  umsatzAndPlanData: KpiLineChartDTO | undefined;
  admMarklineData: any = [];
  admPlansData: any[] = [];
  admIstData = [];
  smooth = 0.2;
  kumulierteDarstellung = false;
  options = this.settingService.avaibleOptions;
  selectedOption: Zeitraum = this.options[0];
  private avms: AdvertisingMedium[] | undefined;
  private lastUmsatzKpis: Map<number, AdvertisingMediumKpi> = new Map<
    number,
    AdvertisingMediumKpi
  >();

  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private kpiService: KpiService,
    private admService: AdvertisingMediumService,
    private settingService: ChartSettingService,
  ) {
    this.settingService
      .rangeSubject$()
      .subscribe((value) => (this.selectedOption = value));
    this.settingService.getKumuliertSubject$().subscribe((value) => {
      this.kumulierteDarstellung = value;
    });
  }

  ngOnInit(): void {
    this.admService.getAdvertisingMediums().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((advM) => {
      this.avms = advM;
      this.createChartOptions();
    });
  }

  private createMarkerLineAndADMBarchart() {
    this.admMarklineData = [];
    this.admPlansData = [];
    this.admIstData = [];
    let d = new Date(2000, 0, 1);
    const von = this.selectedOption.dateRange.von;
    const bis = this.selectedOption.dateRange.bis;
    let abs = 0;
    let gesamtUmsatz = 0;
    if (this.avms === undefined) {
      return;
    }

    this.avms.forEach((adm) => {

      const catType = adm.catalogueType?.id ?? 0;

      if (adm.name && adm.start && catType !== 2) {
        const v = new Date(adm.start);
        let ist: number | undefined = 0;
        if (this.lastUmsatzKpis.has(adm.id)) {
          ist = this.lastUmsatzKpis.get(adm.id)?.totalOrderIntake;
        }
        const umsatz = adm.plannedSalesAmount;
        if (v < von || v > bis) {
          return;
        }
        if (d === v) {
          abs = abs + 100;
          gesamtUmsatz = gesamtUmsatz + umsatz;
        } else {
          abs = 0;
        }
        if (umsatz && umsatz > 0) {
          this.admPlansData.push({
            name: adm.name,
            value: [adm.start, umsatz],
          });
        }
        if (ist && ist > 0) {
          // @ts-ignore
          this.admIstData.push({ name: adm.name, value: [adm.start, ist] });
        }

        this.admMarklineData.push({
          name: adm.name,
          xAxis: adm.start,
          label: { rotate: 90, formatter: '{b}', distance: [abs, 0] },
        });
        d = v;
      }
    });
  }

  createChartOptions() {
    this.createMarkerLineAndADMBarchart();
    //Zeitachse abhängig von den ausgewählten Daten wird erst mal nicht umgesetz.
    switch (this.perpective) {
      case KpiPerspective.OVERVIEW: {
        this.kpiService
          .getKpiUmsatz(
            this.selectedOption.dateRange.von,
            this.selectedOption.dateRange.bis,
            this.channel,
          )
          .pipe(takeUntilDestroyed(this.destroyRef)).subscribe((chart) => {
            const filteredKpiSeries = chart.kpiSeries.filter((serie) =>
              ['order intake', 'order intake py', 'plan'].includes(serie.name.toLowerCase())
            );
            this.umsatzAndPlanData = {
              ...chart,
              kpiSeries: filteredKpiSeries,
            };
            this.setupChart();
            this.ready = true;
          });
        break;
      }
      case KpiPerspective.ORDER_CHANNEL: {
        this.kpiService
          .getKpiOrderChannelUmsatz(
            this.selectedOption.dateRange.von,
            this.selectedOption.dateRange.bis,
          )
          .pipe(takeUntilDestroyed(this.destroyRef)).subscribe((chart) => {
            this.umsatzAndPlanData = chart;
            this.setupChart();
            this.ready = true;
          });
        break;
      }
      default:
    }
  }

  setupChart() {
    const chart = this.umsatzAndPlanData;

    if (chart !== undefined) {
      if (this.kumulierteDarstellung) {
        const currentYear = new Date().getFullYear();

        chart.kpiSeries.forEach((serie)=>{
          let ums = 0;
          serie.data = serie.data.filter((value) => {
            const currentDate = new Date(value[0]);
            return currentDate.getFullYear() === currentYear;
          })
          serie.data.forEach((value) => {
            const currentUms = value[1];
            ums = ums + currentUms;
            value[1] = ums;
          })
        })
      }

      let i = -1;
      chart.kpiSeries.forEach((value, index) => {
        // @ts-ignore
        chart.kpiSeries[index]['symbol'] = 'none';
        chart.kpiSeries[index].smooth = this.smooth;

        if (chart.kpiSeries[index].color === 'palette') {
          delete chart.kpiSeries[index].color;
        }

        if (value.name === 'plan') {
          //chart.kpiSeries[index].color= '2F4F4F';
          i = index;
        }
      });
      if (i === -1) i = 0;
      let loop = new Date(this.selectedOption.dateRange.von);
      const xdata = [];
      while (loop <= this.selectedOption.dateRange.bis) {
        const newDate = loop.setDate(loop.getDate() + 1);

        loop = new Date(newDate);
        xdata.push([loop.toLocaleDateString('en-CA'), 0]);
      }

      const o: any[] = chart.kpiSeries;

      const _legend = { data: [] };
      o.forEach((value) => {
        if (value['name'] && !(value['name'] as string).startsWith('xxx_')) {
          // @ts-ignore
          _legend.data.push({ name: value['name'] });
        }
      });

      // @ts-ignore
      this.kpiOptions = {
        title: {
          text: '', //`${chart.kpiName}`
        },
        legend: {
          ..._legend,
          selected: {
            'order intake': true,
            'order intake PY': true,
            'plan': true,
          },
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        tooltip: [
          {
            trigger: 'axis',
            formatter: function (params: any) {
              let out = params[0].axisValueLabel.substring(0, 10) + '<br>';
              // @ts-ignore
              params.forEach((param) => {
                if (
                  param.componentType === 'series' &&
                  param.seriesName !== 'medium' &&
                  !(param.seriesName as string)?.startsWith('xxx_')
                ) {
                  if (param.componentSubType === 'bar') {
                    out =
                      out +
                      param.name +
                      ': ' +
                      param.seriesName.replace('medium:', '') +
                      ': ' +
                      formatCurrency(
                        +param.value[1],
                        'de',
                        '€',
                        'EUR',
                        '2.0-0',
                      ) +
                      '<br>';
                  } else {
                    out =
                      out +
                      param.seriesName +
                      ': ' +
                      formatCurrency(
                        +param.value[1],
                        'de',
                        '€',
                        'EUR',
                        '2.0-0',
                      ) +
                      '<br>';
                  }
                } else if (param.componentType === 'markLine') {
                  out = out + param.name;
                }
              });

              return out;
            },

            axisPointer: {
              animation: true,
            },
          },
        ],
        dataset: {
          // Provide a set of data.
          //source: data
        },
        // Declare an x-axis (category axis).
        // The category map the first column in the dataset by default.
        xAxis: {
          type: 'time',
        },
        // Declare a y-axis (value axis).
        yAxis: [
          {
            name: 'plan: order intake',
            gridIndex: 0,
            type: 'value',
            scale: true,
            min: this.showNegative ? undefined : 0,
            position: 'left',
          },
        ],

        grid: {
          top: 100,
          left: 100,
          right: 100,
          bottom: 100,
        },

        // Declare several 'bar' series,
        // every series will auto-map to each column by default.
        dataZoom: [
          {
            id: 'dataZoomX',
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'empty',
            showDataShadow: false,
          },
        ],

        series: o,
      };
    }
  }
  changeKumulierteDarstellung(e: any) {
    this.settingService.setKumuliertSubject$(e);
    this.createChartOptions();
  }

  changeBetrachtungsZeitraum(e: any) {
    this.settingService.setRangeSubject$(e);
    this.createChartOptions();
  }
}
