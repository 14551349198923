import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Version } from '../domain/version.model';
import { AdvertisingMedium } from '../domain/advertisingMedium';

export class AdvertisingMediumTargetGroup {
  constructor(
    public id: AdvertisingMediumTargetGroupKey,
    public quantity?: number,
  ) {}
}
export class AdvertisingMediumTargetGroupKey {
  constructor(
    public advertisingMediumId: number,
    public targetGroupId: number,
  ) {}
}

@Injectable({ providedIn: 'root' })
export class AdvertisingMediumService {
  constructor(private httpClient: HttpClient) {}

  getAdvertisingMediums(): Observable<AdvertisingMedium[]> {
    return this.httpClient.get<AdvertisingMedium[]>(`api/advertisingMediums`);
  }
  getAdverstisingMediumVersions(
    key: number | undefined,
  ): Observable<Version[]> {
    return this.httpClient.get<Version[]>(
      `api/advertisingMediums/${key}/versions`,
    );
  }

  getAdvertisingMediums4Campaign(selectedRowKey: any) {
    return this.httpClient.get<AdvertisingMedium[]>(
      `api/campaign/${selectedRowKey}/advertisingMediums`,
    );
  }

  getAdvertisingMediumTargetGroups(advertisingMediumId: number) {
    return this.httpClient.get<AdvertisingMedium>(
      `api/advertisingMediums/${advertisingMediumId}/targetGroup`,
    );
  }

  addAdvertisingMediumTargetGroup(
    advertisingMediumTargetGroup: AdvertisingMediumTargetGroup,
  ) {
    return this.httpClient.post<AdvertisingMediumTargetGroup>(
      `api/advertisingMediums/targetGroup/add`,
      advertisingMediumTargetGroup,
    );
  }

  removeAdvertisingMediumTargetGroup(
    advertisingMediumTargetGroupKey: AdvertisingMediumTargetGroupKey,
  ) {
    return this.httpClient.delete(
      `api/advertisingMediums/targetGroup/delete`, {body: advertisingMediumTargetGroupKey}
    );
  }

  updateAdvertisingMediumTargetGroup(advertisingMediumTargetGroup: AdvertisingMediumTargetGroup) {
    return this.httpClient.put<AdvertisingMediumTargetGroup>(
      `api/advertisingMediums/targetGroup/update`,
      advertisingMediumTargetGroup
    );
  }

}
