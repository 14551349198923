<div class="container mt-2 mb-3">
  <dbc-feedback [status]="status"></dbc-feedback>
  @if(id()) {
    <div class="row mb-4 align-items-center">
      <div class="col-md-8">
        <h2>Details {{ advType() | titlecase }}: {{ title() | titlecase }}</h2>
      </div>
      <div class="col-md-4 text-md-end">
        <button type="button" class="btn btn-outline-primary" [routerLink]="['/app', previousUrl()]">
          Zurück {{ previousUrl() | titlecase }}
        </button>
      </div>
    </div>

    <div>
      <h5>Performance</h5>
    </div>
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              @if (barChart) {
                <am-bar-chart-compare [kpiChartDTO]="barChart" ></am-bar-chart-compare>
              }
            </div>
            <div class="col-6">
              @if (lineChart) {
                <am-line-chart-compare [kpiLineChartDTO]="lineChart"></am-line-chart-compare>
              }
            </div>
          </div>
        </div>
      </div>
    @if (advType() == 'CATALOGUE') {
      <div class="row mt-4 mb-4">
        <div class="col-6">
          @if (barChartKriterium) {
            <app-catalogue-bar-chart [kpiChartDTO]="barChartKriterium" ></app-catalogue-bar-chart>
          }
        </div>
        <div class="col-6">
          @if (barChartScorequantil) {
            <app-catalogue-bar-chart [kpiChartDTO]="barChartScorequantil"></app-catalogue-bar-chart>
          }
        </div>
      </div>
      <!--Pass `id()` signal value to catalogueId, using `!` since @if(id()) ensures the signal is not null. -->
      <app-catalogue-edit
        [catalogueId]="id()!"
        (handleStatus)="handleStatus($event)"
        (pageTitle)="setPageTitle($event)"
      ></app-catalogue-edit>
      } @else if (advType() == 'MAILING') {
      <app-mailing-edit
        [mailingId]="id()!"
        (handleStatus)="handleStatus($event)"
        (pageTitle)="setPageTitle($event)">
      </app-mailing-edit>
      } @else if (advType() == 'EVENT') {
      <app-event-edit
        [eventId]="id()!"
        (handleStatus)="handleStatus($event)"
        (pageTitle)="setPageTitle($event)">
      </app-event-edit>
      } @else if (advType() == 'NEWSLETTER') {
      <app-newsletter-edit
        [newsletterId]="id()!"
        (handleStatus)="handleStatus($event)"
        (pageTitle)="setPageTitle($event)">
      </app-newsletter-edit>
      }
    }
</div>
