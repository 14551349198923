<dx-data-grid
  id="transfergrid"
  [dataSource]="ds"
  [showBorders]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  style=" height: 400px;"
>
  <dxi-column
    caption="headNo"
    dataField="id.headNo"
    dataType="string"
  ></dxi-column>

  <dxi-column
    caption="lineNo"
    dataField="id.lineNo"
    dataType="string"
  ></dxi-column>


  <dxi-column
    caption="qtyToReceive"
    dataField="qtyToReceive"
    dataType="number"
  ></dxi-column>

  <dxi-column
    caption="qtyToShip"
    dataField="qtyToShip"
    dataType="number"
  ></dxi-column>

  <dxi-column
    caption="quantity"
    dataField="quantity"
    dataType="number"
  ></dxi-column>


  <dxi-column
    caption="quantityReceived"
    dataField="quantityReceived"
    dataType="number"
  ></dxi-column>

  <dxi-column
    caption="quantityShipped"
    dataField="quantityShipped"
    dataType="number"
  ></dxi-column>

  <dxi-column
    caption="receiptDate"
    dataField="receiptDate"
    dataType="date"
  ></dxi-column>
  <dxi-column
    caption="shipmentDate"
    dataField="shipmentDate"
    dataType="date"
  ></dxi-column>


  <dxi-column
    caption="transferFromCode"
    dataField="transferFromCode"
    dataType="string"
  ></dxi-column>

  <dxi-column
    caption="transferToCode"
    dataField="transferToCode"
    dataType="string"
  ></dxi-column>

  <dxi-column
    caption="unitMeasure"
    dataField="unitMeasure"
    dataType="string"
  ></dxi-column>

  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-selection mode="single"></dxo-selection>
  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxo-scrolling columnRenderingMode="virtual" mode="infinite"></dxo-scrolling>
  <dxo-paging [enabled]="false"></dxo-paging>

</dx-data-grid>
