<dbc-feedback [status]="status"></dbc-feedback>
<h1 class="display-6">Mailing</h1>
<dx-data-grid
  id="mailGrid"
  [dataSource]="dataSource"
  [showBorders]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
>
  <dxo-selection mode="single"></dxo-selection>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-search-panel
    [visible]="true"
    [width]="240"
    placeholder="Suche..."
  ></dxo-search-panel>
  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
  <dxo-state-storing [enabled]="true" storageKey="mail-grid" type="sessionStorage"></dxo-state-storing>


  <dxo-editing [allowAdding]="true" [allowDeleting]="true" [allowUpdating]="true"
               [useIcons]="true" mode="popup">
    <dxo-form>
      <dxi-item itemType="group" caption="Beschreibung" [colCount]="2" [colSpan]="2">
        <dxi-item dataField="name"></dxi-item>
        <dxi-item dataField="description"></dxi-item>
        <dxi-item dataField="status"
                  [editorOptions]="{placeholder: 'Auswahl'}"
        ></dxi-item>
        <dxi-item dataField="topic"></dxi-item>
        <dxi-item dataField="coupon"></dxi-item>
        <dxi-item dataField="couponNoStart"></dxi-item>
        <dxi-item dataField="couponNoEnd"></dxi-item>
        <dxi-item dataField="discount"></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" caption="Zeitraum" [colCount]="2" [colSpan]="2">
        <dxi-item dataField="start"></dxi-item>
        <dxi-item dataField="end"></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" caption="Kategorisierung" [colCount]="2" [colSpan]="2">
        <dxi-item dataField="previousAdvertisingMedium.id"
                  [editorOptions]="{placeholder: 'Auswahl'}"
        ></dxi-item>
        <dxi-item dataField="campaign.id"
                  [editorOptions]="{placeholder: 'Auswahl'}"
        ></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" caption="Werbebudgetierung" [colCount]="2" [colSpan]="2">
        <dxi-item
          dataField="cost"
          [editorOptions]="{format: {type:'currency',currency:'EUR'}, placeholder: 'Werbekosten in Euro'}"
        ></dxi-item>
        <dxi-item
          dataField="plannedSalesAmount"
          [editorOptions]="{format: {type:'currency',currency:'EUR'}, placeholder: 'Planumsatz in Euro'}"
        ></dxi-item>
      </dxi-item>

      <dxi-item itemType="group" caption="Sonstiges" [colCount]="2" [colSpan]="2">
        <dxi-item dataField="numberOfCopies"></dxi-item>
        <dxi-item dataField="numberOfPages"></dxi-item>
        <dxi-item dataField="remark" colSpan="2"></dxi-item>
        <dxi-item dataField="language"
                  [editorOptions]="{placeholder: 'Auswahl'}"
        ></dxi-item>
        <dxi-item dataField="brand"></dxi-item>
      </dxi-item>
      <dxi-item dataField="coverImage" [editorOptions]="{ value: '>' }" [colCount]="1" [colSpan]="2">
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>
    </dxo-form>
  </dxo-editing>

  <dxi-column
    caption="Edit"
    type="buttons"
    [fixed]="true"
    cellTemplate="linkTemplate"
    dataField="id">
    <div *dxTemplate="let d of 'linkTemplate'">
      <a [routerLink]="['/app','details', d.data.id]" [state]="{ previousRoute: router.url, selectedDataType: 'MAILING'}"
         class="dx-link dx-link-edit dx-icon-edit dx-link-icon" title="Edit" aria-label="Edit">
      </a>
    </div>
  </dxi-column>

  <dxi-column type="buttons" [fixed]="true" caption="Delete">
    <dxi-button name="delete"></dxi-button>
  </dxi-column>

  <dxi-column
    dataField="topic"
    caption="Topic"
  ></dxi-column>

  <dxi-column
    dataField="coupon"
    caption="Coupon"
  ></dxi-column>

  <dxi-column
    dataField="couponNoStart"
    caption="CouponNoStart"
  >
    <dxi-validation-rule type="numeric"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    dataField="couponNoEnd"
    caption="CouponNoEnd"
  >
    <dxi-validation-rule type="numeric"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    dataField="discount"
    caption="Discount"
  >
    <dxi-validation-rule type="numeric"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    dataField="name"
    caption="WTR"
  >
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    dataField="description"
    caption="Beschreibung"
  >
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    dataField="status"
    caption="Status"
  >
    <dxo-lookup [dataSource]="statusLookup"></dxo-lookup>
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    dataField="start"
    caption="Start"
    dataType="date" [format]="{ date: 'dd.MM.yyyy' }"
  >
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    dataField="end"
    caption="Ende"
    dataType="date"
    [format]="{ date: 'dd.MM.yyyy' }"
  >
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    dataField="numberOfCopies"
    caption="Auflage"
  >
    <dxi-validation-rule type="numeric"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    dataField="cost"
    caption="Werbekosten"
    dataType="number"
    [format]="{ style: 'currency', currency: 'EUR' }"
  >
    <dxi-validation-rule type="numeric"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    dataField="remark"
    caption="Notizen"
  ></dxi-column>

  <dxi-column
    dataField="language"
    caption="Sprache"
  >
    <dxo-lookup [dataSource]="languageLookup"></dxo-lookup>
  </dxi-column>

  <dxi-column
    dataField="numberOfPages"
    caption="Seiten"
  >
    <dxi-validation-rule type="numeric"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    dataField="previousAdvertisingMedium.id"
    caption="Vorjahresaktion"
  >
    <dxo-lookup [dataSource]="previousAdvMLookup" displayExpr="description" valueExpr="id"></dxo-lookup>
  </dxi-column>

  <dxi-column
    dataField="plannedSalesAmount"
    caption="Planumsatz"
    dataType="number"
    [format]="{ style: 'currency', currency: 'EUR' }"
  >
    <dxi-validation-rule type="numeric"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    dataField="brand"
    caption="Brand"
  ></dxi-column>
  <dxi-column
          dataField="campaign.id"
          caption="Kampagne"
  >
    <dxo-lookup [dataSource]="campaignLookup" displayExpr="name" valueExpr="id"></dxo-lookup>
  </dxi-column>

  <dxi-column
    dataField="coverImage"
    [width]="100"
    [allowFiltering]="false"
    [allowSorting]="false"
    [visible]="false"
    cellTemplate="cellTemplate"
    editCellTemplate="editCellTemplate"
  ></dxi-column>

  <div *dxTemplate="let data of 'cellTemplate'">
  </div>
  <div *dxTemplate="let data of 'editCellTemplate'">
    <app-cover-image [data]="data.data"></app-cover-image>
  </div>

  <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
  <div *dxTemplate="let cat of 'detail'">
    <div class="row">
      <div class="col-9">
        <detail-version [key]="cat.key"  [buttons]="true"></detail-version>
      </div>
    </div>
  </div>
  <dxo-paging [pageSize]="8"></dxo-paging>
  <dxo-pager
    [showPageSizeSelector]="true"
    [allowedPageSizes]="[8, 10, 15]"
  ></dxo-pager>
</dx-data-grid>
