import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';
import {type} from 'devextreme/core/utils/type';
@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor() { }

  private toast(message: string, type: string){
    notify(
      {
        message: message,
        width: 300
      },
      type,
      500
    );
  }

  public toastSucess(message:string){
    this.toast(message,'success')
  }

  public toastInfo(message:string){
    this.toast(message,'info')
  }

  public toastError(message:string){
    this.toast(message,'error')
  }
  public toastWarning(message:string){
    this.toast(message,'warning')
  }

}
