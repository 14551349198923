import {
  Component,
  DestroyRef, inject,
  input,
  InputSignal, OnInit,
  output,
  OutputEmitterRef,
  signal,
  WritableSignal
} from '@angular/core';
import {FormsModule, NgForm, ReactiveFormsModule} from "@angular/forms";
import {NgbDateStruct, NgbInputDatepicker} from "@ng-bootstrap/ng-bootstrap";
import {Catalogue, CatalogueType} from "../../domain/catalogue.model";
import {AdvertisingMediumLanguage, AdvertisingMediumStatus} from "../../domain/enum.model";
import {AdvertisingMedium} from "../../domain/advertisingMedium";
import {Campaign} from "../../domain/campaign.model";
import {CatalogueService} from "../../services/catalogue.service";
import {CampaignService} from "../../services/campaign.service";
import {Utf8DecoderService} from "../../services/utf8-decoder.service";
import {AuthService} from "../../auth/auth.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {AdvertisedEvent} from "../../domain/event.model";
import {EventService} from "../../services/event.service";
import {EventOrt} from "../../domain/eventOrt.model";
import {AdvertisementEditUtilityService} from "../../services/advertisement-edit-utility.service";

@Component({
  selector: 'app-event-edit',
  standalone: true,
  imports: [
    FormsModule,
    NgbInputDatepicker,
    ReactiveFormsModule
  ],
  templateUrl: './event-edit.component.html',
  styleUrl: './event-edit.component.scss'
})
export class EventEditComponent implements OnInit{
  public eventId: InputSignal<string> = input.required<string>();
  public handleStatus: OutputEmitterRef<{ message: string; type: string }> = output<{ message: string; type: string }>();
  public pageTitle: OutputEmitterRef<string> = output<string>();
  public imagePreview: WritableSignal<string | ArrayBuffer | null> = signal<string | ArrayBuffer | null>(null);
  public selectedImage: WritableSignal<File | null> = signal<File | null>(null);
  public readonly: WritableSignal<boolean> = signal<boolean>(true);
  public userRoles: WritableSignal<string[]> = signal<string[]>([]);
  public adEvent!: AdvertisedEvent;

  public startDateStruct!: NgbDateStruct;
  public endDateStruct!: NgbDateStruct;

  public languageLookup: AdvertisingMediumLanguage[] = [AdvertisingMediumLanguage.DE, AdvertisingMediumLanguage.CH, AdvertisingMediumLanguage.AT, AdvertisingMediumLanguage.FR];
  public statusLookup: AdvertisingMediumStatus[] = [AdvertisingMediumStatus.ABGESCHLOSSEN, AdvertisingMediumStatus.GEPLANT, AdvertisingMediumStatus.LAUFEND];
  public eventOrtsLookup: EventOrt[] = [];
  public previousAdvMLookup: AdvertisingMedium[] = [];
  public campaignLookup: Campaign[] = [];

  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private eveService: EventService, private campService: CampaignService,
    private utf8Decoder: Utf8DecoderService,
    private appRoles: AuthService,
    private adMediumEditService: AdvertisementEditUtilityService) {}

  ngOnInit(): void {
    this.eveService.getEvents().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(events => {
      this.previousAdvMLookup = events
        .map((events) => ({
          ...events,
          name: this.utf8Decoder.decodeString(events?.name),
          description: this.utf8Decoder.decodeString(events?.description),
        }))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    })

    if (this.eventId()) {
      this.eveService.getEventById(this.eventId()).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: (adEvent: AdvertisedEvent) => {
          this.adEvent = { ...adEvent,
            name: this.utf8Decoder.decodeString(adEvent.name),
            description: this.utf8Decoder.decodeString(adEvent.description),
            externalID: this.utf8Decoder.decodeString(adEvent.externalID),
            remark: this.utf8Decoder.decodeString(adEvent.remark),
            brand: this.utf8Decoder.decodeString(adEvent.brand),
            eventOrt: {
              ...adEvent.eventOrt,
              id: adEvent.eventOrt.id,
              name: this.utf8Decoder.decodeString(adEvent.eventOrt?.name),
            },
            campaign: {
              ... adEvent.campaign,
              name: this.utf8Decoder.decodeString(adEvent.campaign?.name),
              description: this.utf8Decoder.decodeString(adEvent.campaign?.description),
            },
            start: new Date(adEvent.start),
            end: new Date(adEvent.end),
          };
          this.startDateStruct = this.adMediumEditService.parseDateToStruct(this.adEvent.start);
          this.endDateStruct = this.adMediumEditService.parseDateToStruct(this.adEvent.end);
          this.fetchDropdownData();
          this.loadCoverImage();
          this.pageTitle.emit(this.adEvent.description);
        },
        error: (error) => {
          const message = `Event mit der ID ${this.eventId()} wurde nicht gefunden.`;
          this.handleStatus.emit({message: message, type: 'Info'});
        }
      })
    }

    const roles: string[] =
      (this.appRoles.roles.value.filter(role => role.trim().length > 0).length > 0)
        ? this.appRoles.roles.value
        : this.appRoles.getStoredRoles().filter(role => role.trim().length > 0);

    this.userRoles.set(roles);
  }

  toggleReadonly(): void {
    this.readonly.update(current => !current);
  }

  fetchDropdownData(): void {

    this.eveService.getEventOrts().subscribe(eventOrts => {
      this.eventOrtsLookup =  eventOrts
        .map((eventOrts) => ({
          ...eventOrts,
          name: this.utf8Decoder.decodeString(eventOrts?.name),
          strasse: this.utf8Decoder.decodeString(eventOrts?.strasse),
          plz: this.utf8Decoder.decodeString(eventOrts?.plz),
          ort: this.utf8Decoder.decodeString(eventOrts?.ort),
          lakz: this.utf8Decoder.decodeString(eventOrts?.lakz),
          status: this.utf8Decoder.decodeString(eventOrts?.status),
        }))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

      if(this.adEvent?.eventOrt) {
        this.adEvent.eventOrt = this.eventOrtsLookup.find(
          (eventOrt) => eventOrt.id == (this.adEvent.eventOrt as EventOrt).id
        ) as EventOrt;
      }

      this.campService.getCampaigns().subscribe(value => {
        this.campaignLookup = value;
        if(this.adEvent?.campaign) {
          this.adEvent.campaign = this.campaignLookup.find(
            (campaign) => campaign.id == (this.adEvent.campaign as Campaign).id
          ) as Campaign;
        }
      })

    });
  }

  onDateSelect(dateStruct: NgbDateStruct, field: 'start' | 'end') {
    this.adEvent[field] = this.adMediumEditService.convertToDate(dateStruct);
  }

  onImageSelected(event: Event): void {
    this.adMediumEditService.handleImageSelection(event, this.selectedImage, this.imagePreview);
  }

  uploadCoverImage(): void {
    if (this.selectedImage && this.adEvent) {
      const selectedImage = this.selectedImage();
      if(selectedImage) {
        this.eveService.uploadImage(this.adEvent, selectedImage).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
          next: () => {
            const message = "Das Bild wurde erfolgreich hochgeladen.";
            this.handleStatus.emit({message: message, type: 'Success'});
            this.loadCoverImage();
          },
          error: (err) => {
            const message = "Fehler beim Hochladen des Bildes.";
            this.handleStatus.emit({message: message, type: 'Info'});
          },
        });
      }
    } else {
      const message = "Bitte wählen Sie ein Bild aus, bevor Sie es hochladen.";
      this.handleStatus.emit({message: message, type: 'Info'});
    }
  }

  loadCoverImage(): void {
    if (this.adEvent) {
      this.eveService.getImage(this.adEvent).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: (imageData) => {
          if (imageData) {
            this.imagePreview.set(`data:image/jpeg;base64,${imageData}`);
          } else {
            this.imagePreview.set(null);
          }
        },
        error: (err) => {
          const message = "Fehler beim Laden des Bildes.";
          this.handleStatus.emit({message: message, type: 'Nope'});
        },
      });
    }
  }

  onSubmit(form: NgForm): void {
    if (form.valid) {
      this.adMediumEditService.prepareSubmission(this.adEvent,  this.startDateStruct, this.endDateStruct, '.Event');
      this.eveService.updateEvent(this.adEvent).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: () => {
          const message = "Die Änderungen wurden erfolgreich übernommen";
          this.handleStatus.emit({message: message, type: 'Success'});
        },
        error: (err) => {
          const message = "Etwas ist schief gelaufen";
          this.handleStatus.emit({message: message, type: 'Info'});        },
      });
    }
  }
}
