<dbc-feedback [status]="status"></dbc-feedback>
<h1 class="display-6">Event</h1>
<dx-data-grid
  [allowColumnReordering]="true"
  [allowColumnResizing]="true"
  [columnAutoWidth]="true"
  [dataSource]="dataSource"
  [filterValue]="['start','anyof',[2025]]"
  [showBorders]="true"
  id="eveGrid"
>
  <dxo-selection mode="single"></dxo-selection>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-panel [visible]="true"></dxo-filter-panel>
  <dxo-search-panel
    [visible]="true"
    [width]="240"
    placeholder="Suche..."
  ></dxo-search-panel>
  <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
  <dxo-state-storing [enabled]="true" storageKey="eve-grid" type="sessionStorage"></dxo-state-storing>

  <dxo-editing [allowAdding]="true" [allowDeleting]="true" [allowUpdating]="true"
               [useIcons]="true" mode="popup">
    <dxo-form>
      <dxi-item [colCount]="2" [colSpan]="2" caption="Beschreibung" itemType="group">
        <dxi-item dataField="name"></dxi-item>
        <dxi-item dataField="description"></dxi-item>
        <dxi-item [editorOptions]="{placeholder: 'Auswahl'}"
                  dataField="status"
        ></dxi-item>

       <dxi-item dataField="eventOrt.id"></dxi-item>
      </dxi-item>

      <dxi-item [colCount]="2" [colSpan]="2" caption="Zeitraum" itemType="group">
        <dxi-item dataField="start"></dxi-item>
        <dxi-item dataField="end"></dxi-item>
      </dxi-item>

      <dxi-item [colCount]="2" [colSpan]="2" caption="Kategorisierung" itemType="group">
        <dxi-item [editorOptions]="{placeholder: 'Auswahl'}"
                  dataField="previousAdvertisingMedium.id"
        ></dxi-item>
        <dxi-item [editorOptions]="{placeholder: 'Auswahl'}"
                  dataField="campaign.id"
        ></dxi-item>
      </dxi-item>

      <dxi-item [colCount]="2" [colSpan]="2" caption="Werbebudgetierung" itemType="group">
        <dxi-item
          [editorOptions]="{format: {type:'currency',currency:'EUR'}, placeholder: 'Werbekosten in Euro'}"
          dataField="cost"
        ></dxi-item>
        <dxi-item
          [editorOptions]="{format: {type:'currency',currency:'EUR'}, placeholder: 'Planumsatz in Euro'}"
          dataField="plannedSalesAmount"
        ></dxi-item>
      </dxi-item>

      <dxi-item [colCount]="2" [colSpan]="2" caption="Sonstiges" itemType="group">
        <dxi-item dataField="numberOfCopies"></dxi-item>
        <dxi-item dataField="numberOfPages"></dxi-item>
        <dxi-item colSpan="2" dataField="remark"></dxi-item>
        <dxi-item [editorOptions]="{placeholder: 'Auswahl'}"
                  dataField="language"
        ></dxi-item>
        <dxi-item dataField="brand"></dxi-item>
      </dxi-item>
      <dxi-item [colCount]="1" [colSpan]="2" [editorOptions]="{ value: '>' }" dataField="coverImage">
        <dxo-label [visible]="false"></dxo-label>
      </dxi-item>
    </dxo-form>
  </dxo-editing>

  <dxi-column
    caption="Edit"
    type="buttons"
    [fixed]="true"
    cellTemplate="linkTemplate"
    dataField="id">
    <div *dxTemplate="let d of 'linkTemplate'">
      <a [routerLink]="['/app','details', d.data.id]" [state]="{ previousRoute: router.url, selectedDataType: 'EVENT'}"
         class="dx-link dx-link-edit dx-icon-edit dx-link-icon" title="Edit" aria-label="Edit">
      </a>
    </div>
  </dxi-column>

  <dxi-column type="buttons" [fixed]="true" caption="Delete">
    <dxi-button name="delete"></dxi-button>
  </dxi-column>

  <dxi-column
    caption="WTR"
    dataField="name"
    dataType="string"
  >
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    caption="Beschreibung"
    dataField="description"
    dataType="string"
  >
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    caption="Status"
    dataField="status"
    dataType="string"
  >
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-lookup [dataSource]="statusLookup"></dxo-lookup>
  </dxi-column>

  <dxi-column
    caption="Event Ort"
    dataField="eventOrt.id"
    dataType="number"
  >
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxo-lookup [dataSource]="eventOrtsLookup" displayExpr="name" valueExpr="id"></dxo-lookup>
  </dxi-column>

  <dxi-column
    [format]="'dd.MM.yyyy'"
    caption="Start"
    dataField="start"
    dataType="date" sortOrder="asc"
  >
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    [format]="'dd.MM.yyyy'"
    caption="Ende"
    dataField="end"
    dataType="date"
  >
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    caption="Auflage"
    dataField="numberOfCopies"
    dataType="number"
  ></dxi-column>

  <dxi-column
    [format]="{ style: 'currency', currency: 'EUR' }"
    caption="Werbekosten"
    dataField="cost"
    dataType="number"
  ></dxi-column>

  <dxi-column
    caption="Notizen"
    dataField="remark"
    dataType="string"
  ></dxi-column>

  <dxi-column
    caption="Sprache"
    dataField="language"
    dataType="number"
  >
    <dxo-lookup [dataSource]="languageLookup"></dxo-lookup>
  </dxi-column>

  <dxi-column
    caption="Seiten"
    dataField="numberOfPages"
    dataType="number"
  ></dxi-column>

  <dxi-column
    caption="Vorjahresaktion"
    dataField="previousAdvertisingMedium.id"
    dataType="number"
  >
    <dxo-lookup [dataSource]="previousAdvMLookup" displayExpr="description" valueExpr="id"></dxo-lookup>
  </dxi-column>

  <dxi-column
    [format]="{ style: 'currency', currency: 'EUR' }"
    caption="Planumsatz"
    dataField="plannedSalesAmount"
    dataType="number"
  >
    <dxi-validation-rule type="numeric"></dxi-validation-rule>
  </dxi-column>

  <dxi-column
    caption="Brand"
    dataField="brand"
    dataType="string"
  ></dxi-column>

  <dxi-column
    caption="Kampagne"
    dataField="campaign.id"
    dataType="number"
  >
    <dxo-lookup [dataSource]="campaignLookup" displayExpr="name" valueExpr="id"></dxo-lookup>
  </dxi-column>

  <dxi-column
    [allowFiltering]="false"
    [allowSorting]="false"
    [visible]="false"
    [width]="100"
    cellTemplate="cellTemplate"
    dataField="coverImage"
    dataType="string"
    editCellTemplate="editCellTemplate"
  ></dxi-column>

  <div *dxTemplate="let data of 'cellTemplate'">
  </div>
  <div *dxTemplate="let data of 'editCellTemplate'">
    <app-cover-image [data]="data.data"></app-cover-image>
  </div>

  <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
  <div *dxTemplate="let eve of 'detail'">
    <div class="row">
      <div class="col-9">
        <detail-version [buttons]="true" [key]="eve.key"></detail-version>
      </div>
    </div>
  </div>
  <dxo-paging [pageSize]="8"></dxo-paging>
  <dxo-pager
    [allowedPageSizes]="[8, 10, 15]"
    [showPageSizeSelector]="true"
  ></dxo-pager>
</dx-data-grid>


