import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { PreviousRouteService } from '../../services/previous-url-service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { JsonPipe } from '@angular/common';

import { AuthService } from '../../auth/auth.service';
import {
  AdvertisingMediumService,
  AdvertisingMediumTargetGroup,
  AdvertisingMediumTargetGroupKey
} from "../../services/advertising-medium.service";

interface ProfileType {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
}

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  standalone: true,
  imports: [JsonPipe],
})
export class WelcomeComponent implements OnInit {
  profile: ProfileType | undefined;
  serverProfile: never | undefined;

  public message = '';
  //private store: Store<{ user: User }>, private _router: Router, private authGuard :AuthGuard,private userService: UserService,
  constructor(
    private previousRouteService: PreviousRouteService,
    private advertisingMediumService: AdvertisingMediumService,
    private _router: Router,
    private authService: AuthService,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    this.getProfile(environment.apiConfig.uri);
    this.advertisingMediumService.getAdvertisingMediumTargetGroups(165).subscribe({
      next: (targetGroups) => {
        console.log("targetGroups: ", targetGroups);
      }
    })

    // this.authService.roles.subscribe((roles: string[]) => {
    // if (roles.includes(AuthorityEnum.ADMIN)) {
    //   this._router.navigate(['app/overview']);
    // }
    // if (roles.includes(AuthorityEnum.PURCHASE)) {
    //   this._router.navigate(['app/purchase-products']);
    // }
    // if (roles.includes(AuthorityEnum.MARKETING)) {
    //   this._router.navigate(['app/campaign']);
    // }
    // });
  }

  getProfile(url: string) {
    // this.http.get('api/users/me').subscribe(value => {
    //   this.serverProfile=value;
    // })
    this.http.get(url).subscribe((profile) => {
      this.profile = profile;
    });
  }

  //ngOnInit() {

  // const userStore= this.store.select('user');
  // userStore.subscribe(value => {
  //   const ok=this.authGuard.userOk(value)
  //   if(ok){
  //
  //     const url = this.previousRouteService.getPreviousUrl();
  //     if(url){
  //       this._router.navigateByUrl(url)
  //     }else {
  //       if ( true ){ //value.authorities.includes(AuthorityEnum.PURCHASE)){
  //         this._router.navigate(['purchase-products'])
  //       } else {
  //         this._router.navigate(['overview'])
  //       }
  //
  //     }
  //
  //   } else {
  //     this.message=`Es bestehen Probleme für den user: ${value.domainLogin}`
  //   }
  // })

  //}

  // loadUserInfo() {
  //   this.userService.getUserInfo().subscribe(
  //     _user => {
  //       this.store.dispatch(UserActions.loadUserSuccess(_user));
  //     }
  //   )
  // }

  deleteTargetGroup() {
    const groupKey: AdvertisingMediumTargetGroupKey = {
      advertisingMediumId: 165,
      targetGroupId: 1,
    };

    this.advertisingMediumService
      .removeAdvertisingMediumTargetGroup(groupKey)
      .subscribe(() => {});
  }

  addTargetGroup() {
    const tg: AdvertisingMediumTargetGroup = {
      id: { advertisingMediumId: 165, targetGroupId: 1 },
      quantity: 10,
    };

    this.advertisingMediumService
      .addAdvertisingMediumTargetGroup(tg)
      .subscribe(() => {});
  }
}
