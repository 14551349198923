<div class="modal-header">
  <h5>Referrer zu {{ rowData?.name }}</h5>
</div>
<div class="modal-body">
  <div class="row p-2">
    <dx-data-grid
      id="referrerGrid"
      [dataSource]="dataSource"
      [showBorders]="true"
      [allowColumnResizing]="true"
      [allowColumnReordering]="true"
      [columnAutoWidth]="true"
    >
      <dxo-selection mode="single"></dxo-selection>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-editing [allowAdding]="true" [allowDeleting]="true" [allowUpdating]="true"
                   [useIcons]="true" mode="popup">
        <dxo-form>
            <dxi-item dataField="name"></dxi-item>
          <dxi-item dataField="type"></dxi-item>
        </dxo-form>
      </dxo-editing>
      <dxi-column
        dataField="name"
        caption="Name"
        datatype="string"
      >
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>
      <dxi-column
        dataField="type"
        caption="Type"
        datatype="string"
      >
      </dxi-column>

    </dx-data-grid>


<!--    {{rowData|json}}-->
  </div>
</div>
<div class="modal-footer">
  <button (click)="back()"
          autofocus
          class="btn btn-sm btn-outline-secondary"
          id="modal-confirm"
          type="button">
    OK
  </button>
</div>
