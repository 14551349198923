import {Component, OnInit, ViewChild} from '@angular/core';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import {ProductKpiService} from '../../services/product-kpi.service';
import {lastValueFrom} from 'rxjs';
import {Product, ProductKpiDTO} from '../../domain/product.model';
import {GridStateService} from '../../services/grid-state.service';
import {NotifyService} from '../../services/notify.service';
import {EisUserGridStateDTO, GridState} from '../../domain/grid-state.model';
import {Workbook} from 'exceljs';
//import { saveAs } from 'file-saver-es';
import {exportDataGrid} from 'devextreme/excel_exporter';
import {EisUserService} from '../../services/eis-user.service';
import {saveAs} from 'file-saver';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OverviewDetailComponent} from '../overview/overview-detail/overview-detail.component';
import {ProductDetailComponent} from './product-detail/product-detail.component';
import DevExpress from 'devextreme';
import { NgIf, NgFor } from '@angular/common';
import { GridStateComponent } from '../grid-state/grid-state.component';
import { DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoScrollingModule, DxoPagingModule, DxoSummaryModule, DxiTotalItemModule, DxiColumnModule, DxoFormatModule, DxoExportModule } from 'devextreme-angular/ui/nested';
import { DxTemplateModule } from 'devextreme-angular/core';
import ColumnButtonClickEvent = DevExpress.ui.dxDataGrid.ColumnButtonClickEvent;
import {AuthorityEnum} from '../../domain/user.model';
import {MsalService} from '@azure/msal-angular';
import {AuthService} from '../../auth/auth.service';
import {Utf8DecoderService} from "../../services/utf8-decoder.service";

@Component({
    selector: 'app-purchase-product',
    templateUrl: './purchase-product.component.html',
    styleUrls: ['./purchase-product.component.scss'],
    standalone: true,
    imports: [NgIf, GridStateComponent, NgFor, DxDataGridModule, DxoSortingModule, DxoSelectionModule, DxoFilterRowModule, DxoHeaderFilterModule, DxoFilterPanelModule, DxoSearchPanelModule, DxoColumnChooserModule, DxoStateStoringModule, DxoScrollingModule, DxoPagingModule, DxoSummaryModule, DxiTotalItemModule, DxiColumnModule, DxTemplateModule, DxoFormatModule, DxoExportModule]
})
export class PurchaseProductComponent implements OnInit{

  // @ts-ignore
  @ViewChild(DxDataGridComponent) purchaseGrid: DxDataGridComponent;
  public dataSource: DataSource;
  public selectedProductId: any;
  private stateKey='purchase-grid';
  public keyOut: string|undefined;

  public currentState: any;
  public loadedGridState: GridState|undefined;
  public isAdmin: boolean=false;
  public myStates: EisUserGridStateDTO[]=[];

  constructor(private productKpiService: ProductKpiService,
              private msal: MsalService,
              private userRole: AuthService,
              private gridStateService: GridStateService,
              private notifyService : NotifyService,
              private  eisUserService: EisUserService,
              private router: Router,
              private modalService: NgbModal,
              private utf8Decoder: Utf8DecoderService) {
    this.saveState = this.saveState.bind(this);
    this.toggleBestellStop=this.toggleBestellStop.bind(this);

    this.dataSource = new DataSource({
      key: 'productId',
      load: () => {
        return lastValueFrom(this.productKpiService.getProductKpis()).then((rProducts: ProductKpiDTO[]) => {
          this.keyOut=this.stateKey;
          rProducts = rProducts.map((product)=>({
            ...product,
            productId: this.utf8Decoder.decodeString(product?.productId),
            productName: this.utf8Decoder.decodeString(product?.productName),
            descriptionLevelOne: this.utf8Decoder.decodeString(product?.descriptionLevelOne),
            descriptionLevelTwo: this.utf8Decoder.decodeString(product?.descriptionLevelTwo),
            descriptionLevelThree: this.utf8Decoder.decodeString(product?.descriptionLevelThree),
            groupLevel1: this.utf8Decoder.decodeString(product?.groupLevel1),
            groupLevel2: this.utf8Decoder.decodeString(product?.groupLevel2),
          }))
          return {
            data:rProducts,
            totalCount: rProducts.length
          };
        }).catch((err) => {
          throw Error(err.message);
        });
      }
    })
  }

  ngOnInit() {

        this.isAdmin = this.userRole.roles.value.includes(AuthorityEnum.ADMIN)
        const email: string = this.getUserEmail();
        this.eisUserService.getUserGridState(email).subscribe(value => {
          this.myStates = value
          const defaultState = this.myStates.find(state => {
            return state.defaultstate
          })

          if (defaultState) {
            const _defaultState = {...defaultState};
            const gs: GridState = {
              stateName: <string>_defaultState.statename,
              jsonState: _defaultState.stateJson,
              gridKey: this.stateKey
            };
            this.purchaseGrid.instance.state(gs.jsonState);
            this.loadedGridState = gs
          } else if (this.myStates.length > 0) {
            const _state = {...this.myStates[0]};
            const gs: GridState = {
              stateName: _state.statename,
              jsonState: _state.stateJson,
              gridKey: this.stateKey
            };
            this.purchaseGrid.instance.state(gs.jsonState);
            this.loadedGridState = gs
          }

        })
  }
  private getUserEmail(): string {
    return <string>this.msal.instance.getActiveAccount()?.username;
  }

  selectionChanged(e: any) {
    if(e.selectedRowKeys.length===1){
      this.selectedProductId=e.selectedRowKeys[0];
    }
  }

  onStateResetClick() {
    this.purchaseGrid.instance.state(null);
    this.loadedGridState = undefined;
  }

  // diese Methode wird vom Grid aufgerufen wenn sich der State geändert hat
  saveState(stat: any) {
    this.currentState=stat
  }

  loadStateFromChild($event: GridState) {
    this.loadedGridState=$event;
    this.purchaseGrid.instance.state($event.jsonState);
  }
  onExporting(e: any) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Daten');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'purchase-data.xlsx');
      });
    });
  }

  loadMyState(statename: string) {

    const state= this.myStates.find(value => {
      return value.statename===statename
    })
    if(state){
       const _state = {...state};
      const gs: GridState = {
        stateName: _state.statename,
        jsonState: _state.stateJson,
        gridKey: this.stateKey
      };
      this.loadedGridState=gs;
       this.purchaseGrid.instance.state(_state.stateJson);
    }

  }

  toggleBestellStop(e: any) {
    // @ts-ignore
    this.productKpiService.toggleBestellStop(e).subscribe(value => {
      this.notifyService.toastInfo("Bestellstop wurde gesetzt.")
      this.purchaseGrid.instance.refresh();
    })
  }



  routeToDetails(product: ProductKpiDTO) {
    const modalRef = this.modalService.open(ProductDetailComponent, { fullscreen: true });
    modalRef.componentInstance.product = product;
  }
}
