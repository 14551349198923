/* src/main/webapp/app/components/landing-page/landing-page.component.scss */
div[id$=-section] {
  background-color: #f8f9fa;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}
div[id$=-section]:hover {
  transform: scale(1.01);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}
div[id$=-section] h2 {
  font-size: 1.75rem;
  font-weight: 600;
  color: #343a40;
  margin-bottom: 10px;
}
div[id$=-section] p {
  font-size: 1rem;
  line-height: 1.5;
  color: #6c757d;
}
@media (max-width: 768px) {
  div[id$=-section] {
    padding: 15px;
  }
  div[id$=-section] h2 {
    font-size: 1.5rem;
  }
  div[id$=-section] p {
    font-size: 0.9rem;
  }
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: underline;
  color: blue;
}
/*# sourceMappingURL=landing-page.component-OUK7VEPU.css.map */
