import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot, Router
} from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import {AuthorityEnum, GraphUser} from '../domain/user.model';
import {AuthService} from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(private appRoles: AuthService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let authorized = false;
    const userRoles: string[] = this.appRoles.roles.value;
    const roles = route.data['roles'] as AuthorityEnum[];
    roles.forEach((role) => {
      if (userRoles.includes(role)) {
        authorized = true;
      }
    });
    return true;
  }
}
