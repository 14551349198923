import {DestroyRef, inject, Injectable, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {environment} from "../../environments/environment";
import {AppUser, AuthorityEnum, GraphUser} from "../domain/user.model";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {PreviousRouteService} from "../services/previous-url-service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  graphProfile: GraphUser | undefined;
  roles: BehaviorSubject<string[]> = new BehaviorSubject(['']);
  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(private readonly http: HttpClient, private _router: Router, private previousRouteService: PreviousRouteService) {}

  setAppAuthRoles() {
    this.http.get(environment.apiConfig.uri).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((profile) => {
      this.graphProfile = profile as GraphUser;
      this.http.get(`api/users/${this.graphProfile.userPrincipalName}`).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
        const appUser: AppUser = value as AppUser;

        this.roles.next(appUser.roles.map((role) => role.name));
        sessionStorage.setItem('roles', JSON.stringify(appUser.roles));

        const url = this.previousRouteService.getPreviousUrl();
        // todo previous url handling
        if (this.roles.value.includes(AuthorityEnum.PURCHASE)) { //value.authorities.includes(AuthorityEnum.PURCHASE)){
          this._router.navigate(['app/purchase-products']);
        }
      });
    });
  }

  clearStoredRoles():void {
    sessionStorage.removeItem('roles');
  }

  getStoredRoles(): string[] {
    const storedRoles: string | null = sessionStorage.getItem("roles");
    if (storedRoles) {
      const parsedRoles = JSON.parse(storedRoles);
      return parsedRoles.map((role: { name: string }) => role.name);
    }
    return [];
  }

}
